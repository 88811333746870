define('@abcum/ember-helpers/helpers/pattern-email', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.patternEmail = patternEmail;
	function patternEmail() {
		return '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$';
	}

	exports.default = Ember.Helper.helper(patternEmail);
});