define('@abcum/ember-helpers/helpers/pattern-letters', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.patternLetters = patternLetters;
	function patternLetters() {
		return '[a-zA-Z]+';
	}

	exports.default = Ember.Helper.helper(patternLetters);
});