define('ember-data-model-fragments/ext', ['exports', 'ember-data/store', 'ember-data/model', 'ember-data/-private', 'ember-data/serializers/json', 'ember-data-model-fragments/states', 'ember-data-model-fragments/fragment', 'ember-data-model-fragments/array/fragment'], function (exports, _store, _model, _private, _json, _states, _fragment, _fragment2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.JSONSerializer = exports.Model = exports.Store = undefined;


  function serializerForFragment(owner, normalizedModelName) {
    let serializer = owner.lookup(`serializer:${normalizedModelName}`);

    if (serializer !== undefined) {
      return serializer;
    }

    // no serializer found for the specific model, fallback and check for application serializer
    serializer = owner.lookup('serializer:-fragment');
    if (serializer !== undefined) {
      return serializer;
    }

    // final fallback, no model specific serializer, no application serializer, no
    // `serializer` property on store: use json-api serializer
    serializer = owner.lookup('serializer:-default');

    return serializer;
  }
  /**
    @module ember-data-model-fragments
  */

  const InternalModelPrototype = _private.InternalModel.prototype;
  const RecordDataPrototype = _private.RecordData.prototype;

  Object.assign(RecordDataPrototype, {
    eachFragmentKey(fn) {
      this._fragments = this._fragments || Object.create({});
      Object.keys(this._fragments).forEach(fn);
    },

    eachFragmentKeyValue(fn) {
      this.eachFragmentKey(key => {
        const value = this.getFragment(key);
        if (value) {
          fn(key, value);
        }
      });
    },

    getOwner() {
      return this._owner;
    },

    setOwner(value) {
      this._owner = value;
    },

    setName(value) {
      this._name = value;
    },

    getName() {
      return this._name;
    },

    getFragment(name) {
      this._fragments = this._fragments || Object.create({});
      return this._fragments[name];
    },

    setFragment(name, fragment) {
      this._fragments = this._fragments || Object.create({});
      this._fragments[name] = fragment;
      return this._fragments[name];
    },

    didCommit(data) {
      this._isNew = false;
      if (data) {
        if (data.relationships) {
          this._setupRelationships(data);
        }
        if (data.id) {
          // didCommit provided an ID, notify the store of it
          this.storeWrapper.setRecordId(this.modelName, data.id, this.clientId);
          this.id = (0, _private.coerceId)(data.id);
        }
        data = data.attributes;

        // Notify fragments that the record was committed
        this.eachFragmentKeyValue((key, fragment) => fragment._adapterDidCommit(data[key]));
      } else {
        this.eachFragmentKeyValue((key, fragment) => fragment._adapterDidCommit());
      }

      const changedKeys = this._changedKeys(data);

      Object.assign(this._data, this.__inFlightAttributes, this._attributes, data);
      this._attributes = null;
      this._inFlightAttributes = null;
      this._updateChangedAttributes();

      return changedKeys;
    }
  });

  /**
    @class Store
    @namespace DS
  */
  _store.default.reopen({
    /**
      Create a new fragment that does not yet have an owner record.
      The properties passed to this method are set on the newly created
      fragment.
       To create a new instance of the `name` fragment:
       ```js
      store.createFragment('name', {
        first: 'Alex',
        last: 'Routé'
      });
      ```
       @method createRecord
      @param {String} type
      @param {Object} properties a hash of properties to set on the
        newly created fragment.
      @return {MF.Fragment} fragment
    */
    createFragment(modelName, props) {
      (true && !(this.isFragment(modelName)) && Ember.assert(`The '${modelName}' model must be a subclass of MF.Fragment`, this.isFragment(modelName)));


      let internalModel = new _private.InternalModel(modelName, null, this, Ember.getOwner(this).container);

      // Re-wire the internal model to use the fragment state machine
      internalModel.currentState = _states.default.empty;

      internalModel._recordData._name = null;
      internalModel._recordData.setOwner(null);

      internalModel.loadedData();

      let fragment = internalModel.getRecord();

      if (props) {
        fragment.setProperties(props);
      }

      // invoke the ready callback ( to mimic DS.Model behaviour )
      fragment.trigger('ready');

      // Add brand to reduce usages of `instanceof`
      fragment._isFragment = true;

      return fragment;
    },

    /**
      Returns true if the modelName is a fragment, false if not
       @method isFragment
      @private
      @param {String} the modelName to check if a fragment
      @return {boolean}
    */
    isFragment(modelName) {
      if (modelName === 'application' || modelName === '-default') {
        return false;
      }

      let type = this.modelFor(modelName);
      return _fragment.default.detect(type);
    },

    serializerFor(modelName) {
      (true && !(Ember.isPresent(modelName)) && Ember.assert('You need to pass a model name to the store\'s serializerFor method', Ember.isPresent(modelName)));
      (true && !(typeof modelName === 'string') && Ember.assert(`Passing classes to store.serializerFor has been removed. Please pass a dasherized string instead of ${modelName}`, typeof modelName === 'string'));


      let owner = Ember.getOwner(this);
      let normalizedModelName = (0, _private.normalizeModelName)(modelName);

      if (this.isFragment(normalizedModelName)) {
        return serializerForFragment(owner, normalizedModelName);
      } else {
        return this._super(...arguments);
      }
    }
  });

  /**
    @class Model
    @namespace DS
    */
  _model.default.reopen({
    willDestroy() {
      this._super(...arguments);

      let internalModel = (0, _fragment.internalModelFor)(this);
      let key, fragment;

      // destroy the current state
      for (key in internalModel._recordData._fragments) {
        fragment = internalModel._recordData._fragments[key];
        if (fragment) {
          fragment.destroy();
          delete internalModel._recordData._fragments[key];
        }
      }

      // destroy the original state
      for (key in internalModel._recordData._data) {
        fragment = internalModel._recordData._data[key];
        if (fragment instanceof _fragment.default || fragment instanceof _fragment2.default) {
          fragment.destroy();
          delete internalModel._recordData._data[key];
        }
      }
    }
  });

  _model.default.reopenClass({
    fields: Ember.computed(function () {
      let map = new Map();

      this.eachComputedProperty((name, meta) => {
        if (meta.isFragment) {
          map.set(name, 'fragment');
        } else if (meta.isRelationship) {
          map.set(name, meta.kind);
        } else if (meta.isAttribute) {
          map.set(name, 'attribute');
        }
      });

      return map;
    }).readOnly()

  });

  // Replace a method on an object with a new one that calls the original and then
  // invokes a function with the result
  function decorateMethod(obj, name, fn) {
    let originalFn = obj[name];

    obj[name] = function () {
      let value = originalFn.apply(this, arguments);

      return fn.call(this, value, arguments);
    };
  }

  function decorateMethodBefore(obj, name, fn) {
    const originalFn = obj[name];
    obj[name] = function () {
      fn.apply(this, arguments);
      return originalFn.apply(this, arguments);
    };
  }

  /**
    Override parent method to snapshot fragment attributes before they are
    passed to the `DS.Model#serialize`.
  
    @method _createSnapshot
    @private
  */
  decorateMethod(InternalModelPrototype, 'createSnapshot', function createFragmentSnapshot(snapshot) {
    let attrs = snapshot._attributes;
    Object.keys(attrs).forEach(key => {
      let attr = attrs[key];
      // If the attribute has a `_createSnapshot` method, invoke it before the
      // snapshot gets passed to the serializer
      if (attr && typeof attr._createSnapshot === 'function') {
        attrs[key] = attr._createSnapshot();
      }
    });

    return snapshot;
  });

  decorateMethod(InternalModelPrototype, 'adapterDidError', function adapterDidErrorFragments(returnValue, args) {
    const error = args[0] || Object.create(null);
    this._recordData.eachFragmentKeyValue((key, value) => {
      value._adapterDidError(error);
    });
  });

  decorateMethod(InternalModelPrototype, 'rollbackAttributes', function rollbackFragments() {
    this._recordData.eachFragmentKeyValue((key, value) => {
      value.rollbackAttributes();
    });
  });

  decorateMethod(RecordDataPrototype, 'changedAttributes', function changedAttributes(diffData) {
    this.eachFragmentKey(name => {
      if (name in this._attributes) {
        diffData[name] = [diffData[name][0], diffData[name][1] ? diffData[name][1]._record : diffData[name][1]];
      }
    });
    return diffData;
  });

  decorateMethodBefore(RecordDataPrototype, 'willCommit', function willCommit() {
    this.eachFragmentKeyValue((key, fragment) => fragment._flushChangedAttributes());
  });

  decorateMethodBefore(RecordDataPrototype, 'commitWasRejected', function commitWasRejected() {
    this.eachFragmentKeyValue((key, fragment) => fragment._adapterDidError());
  });

  /**
    @class JSONSerializer
    @namespace DS
  */
  _json.default.reopen({
    /**
      Enables fragment properties to have custom transforms based on the fragment
      type, so that deserialization does not have to happen on the fly
       @method transformFor
      @private
    */
    transformFor(attributeType) {
      if (attributeType.indexOf('-mf-') !== 0) {
        return this._super(...arguments);
      }

      const owner = Ember.getOwner(this);
      const containerKey = `transform:${attributeType}`;

      if (!owner.hasRegistration(containerKey)) {
        const match = attributeType.match(/^-mf-(fragment|fragment-array|array)(?:\$([^$]+))?(?:\$(.+))?$/);
        const transformName = match[1];
        const type = match[2];
        const polymorphicTypeProp = match[3];
        let transformClass = owner.factoryFor(`transform:${transformName}`);
        transformClass = transformClass && transformClass.class;
        transformClass = transformClass.extend({
          type,
          polymorphicTypeProp,
          store: this.store
        });
        owner.register(containerKey, transformClass);
      }
      return owner.lookup(containerKey);
    }
  });

  exports.Store = _store.default;
  exports.Model = _model.default;
  exports.JSONSerializer = _json.default;
});