define('@abcum/ember-helpers/helpers/is-fulfilled', ['exports', '@abcum/ember-helpers/helpers/await'], function (exports, _await) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = _await.default.extend({

		value: false,

		process(promise) {

			promise.then(() => {
				this.set('value', true);
			}).catch(() => {
				this.set('value', false);
			});

			return this.get('value');
		}

	});
});