define('@abcum/ember-helpers/helpers/history-forward', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({

		compute() {
			return function () {
				window.history.forward();
				return;
			};
		}

	});
});