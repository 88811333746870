define('@abcum/ember-helpers/helpers/pattern-country', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.patternCountry = patternCountry;
	function patternCountry() {
		return '[A-Z]{3}';
	}

	exports.default = Ember.Helper.helper(patternCountry);
});