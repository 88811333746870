define("ember-models-table/helpers/exists-in", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.extend({
    content: Ember.computed('needle', 'haystack.[]', function () {
      let needle = Ember.get(this, 'needle');
      let haystack = Ember.get(this, 'haystack');
      return Ember.isArray(haystack) ? haystack.includes(needle) : false;
    }).readOnly(),

    compute([haystack, needle]) {
      Ember.set(this, 'needle', needle);
      Ember.set(this, 'haystack', haystack);
      return Ember.get(this, 'content');
    },

    contentDidChange: Ember.observer('content', function () {
      this.recompute();
    })
  });

  _exports.default = _default;
});