define('@abcum/ember-helpers/helpers/slug', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.slug = slug;
	function slug([value = '']) {
		return String(value).replace(/['"]/gi, '').replace(/[\s.]/gi, '-').replace(/(?![a-zA-Z0-9])/gi, '').toLowerCase();
	}

	exports.default = Ember.Helper.helper(slug);
});