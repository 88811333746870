define('@abcum/ember-helpers/helpers/drag-get', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.dragGet = dragGet;
	function dragGet([type]) {
		return function (event) {
			let value = event.dataTransfer.getData(type);
			if (type === 'application/json') {
				return JSON.parse(value);
			}
			return value;
		};
	}

	exports.default = Ember.Helper.helper(dragGet);
});