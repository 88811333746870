define('@abcum/ember-helpers/helpers/pattern-integer', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.patternInteger = patternInteger;
	function patternInteger() {
		return '([+|-])?([0-9]+)';
	}

	exports.default = Ember.Helper.helper(patternInteger);
});