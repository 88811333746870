define("@abcum/ember-helpers/helpers/md5", ["exports", "@abcum/ember-helpers/utils/md5"], function (exports, _md) {
	"use strict";

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.md5 = md5;
	function md5(params) {
		return (0, _md.default)(params[0]);
	}

	exports.default = Ember.Helper.helper(md5);
});