define('@abcum/ember-helpers/helpers/debounce', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.debounce = debounce;
	function debounce([callback, wait], { immediate = false }) {
		return function (value) {
			return Ember.run.debounce(null, callback, value, wait, immediate);
		};
	}

	exports.default = Ember.Helper.helper(debounce);
});