define("@jftechnology/ember-keycloak-auth/services/keycloak-session", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _descriptor;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  const {
    Promise
  } = Ember.RSVP;
  /**
   * Injectable Ember service that wraps an application wide Keycloak js instance.
   *
   * @class KeycloakSessionService
   * @public
   */

  let KeycloakSessionService = (_dec = Ember.inject.service('router'), _dec2 = Ember.computed('timestamp'), _dec3 = Ember.computed('timestamp'), _dec4 = Ember.computed('timestamp'), _dec5 = Ember.computed('timestamp'), _dec6 = Ember.computed('timestamp'), (_class = class KeycloakSessionService extends Ember.Service {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "router", _descriptor, this);

      _defineProperty(this, "name", 'keycloak session');

      _defineProperty(this, "minValidity", 30);

      _defineProperty(this, "ready", false);

      _defineProperty(this, "authenticated", false);

      _defineProperty(this, "timestamp", null);

      _defineProperty(this, "onLoad", 'login-required');

      _defineProperty(this, "responseMode", 'fragment');

      _defineProperty(this, "flow", 'standard');

      _defineProperty(this, "checkLoginIframe", false);

      _defineProperty(this, "checkLoginIframeInterval", 5);

      _defineProperty(this, "idpHint", null);

      _defineProperty(this, "onReady", authenticated => {
        console.debug(`Keycloak session :: onReady ${authenticated}`);
        Ember.set(this, 'ready', true);
        Ember.set(this, 'authenticated', authenticated);
        Ember.set(this, 'timestamp', new Date());
      });

      _defineProperty(this, "onAuthSuccess", () => {
        console.debug('Keycloak session :: onAuthSuccess');
        Ember.set(this, 'authenticated', true);
        Ember.set(this, 'timestamp', new Date());
      });

      _defineProperty(this, "onAuthError", () => {
        console.debug('onAuthError');
        Ember.set(this, 'authenticated', false);
        Ember.set(this, 'timestamp', new Date());
      });

      _defineProperty(this, "onAuthRefreshSuccess", () => {
        console.debug('onAuthRefreshSuccess');
        Ember.set(this, 'authenticated', true);
        Ember.set(this, 'timestamp', new Date());
      });

      _defineProperty(this, "onAuthRefreshError", () => {
        console.debug('onAuthRefreshError');
        Ember.set(this, 'authenticated', false);
        Ember.set(this, 'timestamp', new Date());
        this.clearToken();
      });

      _defineProperty(this, "onTokenExpired", () => {
        console.debug('onTokenExpired');
        Ember.set(this, 'authenticated', false);
        Ember.set(this, 'timestamp', new Date());
      });

      _defineProperty(this, "onAuthLogout", () => {
        console.debug('onAuthLogout');
        Ember.set(this, 'authenticated', false);
        Ember.set(this, 'timestamp', new Date());
      });
    }

    init() {
      super.init(...arguments);
      this.router.on('routeWillChange', this, 'routeWillChange');
    }

    routeWillChange(transition) {
      let routeInfo = transition.to;

      if (routeInfo) {
        let info = routeInfo.find(info => Ember.get(info, 'metadata.updateKeycloakToken'));

        if (info) {
          this.checkTransition(transition);
        }
      }
    }
    /**
     * Keycloak callback function.
     *
     * @property onReady
     * @type {Function}
     */


    /**
     * @method installKeycloak
     * @param {*[]} parameters Constructor parameters for Keycloak object - see Keycloak JS adapter docs for details
     */
    installKeycloak(parameters) {
      console.debug('Keycloak session :: keycloak');
      let keycloak = new Keycloak(parameters);

      this._installKeycloak(keycloak);
    }

    _installKeycloak(keycloak) {
      keycloak.onReady = this.onReady;
      keycloak.onAuthSuccess = this.onAuthSuccess;
      keycloak.onAuthError = this.onAuthError;
      keycloak.onAuthRefreshSuccess = this.onAuthRefreshSuccess;
      keycloak.onAuthRefreshError = this.onAuthRefreshError;
      keycloak.onTokenExpired = this.onTokenExpired;
      keycloak.onAuthLogout = this.onAuthLogout;
      Ember.set(this, '_keycloak', keycloak);
      Ember.set(this, 'timestamp', new Date());
      console.debug('Keycloak session :: install :: completed');
    }
    /**
     * @method initKeycloak
     */


    initKeycloak() {
      console.debug('Keycloak session :: init');
      let options = this.getProperties('onLoad', 'responseMode', 'checkLoginIframe', 'checkLoginIframeInterval', 'flow');

      if (this.keycloak) {
        return new Promise((resolve, reject) => {
          this.keycloak.init(options).success(authenticated => {
            console.info('Keycloak session ::  init success');
            resolve(authenticated);
          }).error(reason => {
            console.warn('Keycloak session ::  init failed');
            reject(reason);
          });
        });
      }
    }
    /**
     * The wrapped Keycloak instance.
     *
     * @property keycloak
     * @type {Keycloak}
     */


    get keycloak() {
      return this._keycloak;
    }
    /**
     * The current Keycloak subject.
     *
     * @property subject
     * @type {string}
     */


    get subject() {
      return this.keycloak.subject;
    }
    /**
     * The current Keycloak refreshToken.
     *
     * @property refreshToken
     * @type {string}
     */


    get refreshToken() {
      return this.keycloak.refreshToken;
    }
    /**
     * The current Keycloak token.
     *
     * @property token
     * @type {string}
     */


    get token() {
      return this.keycloak.token;
    }
    /**
     * The current Keycloak tokenParsed.
     *
     * @property tokenParsed
     * @type {string}
     */


    get tokenParsed() {
      return this.keycloak.tokenParsed;
    }
    /**
     * Delegates to the wrapped Keycloak instance's method.
     *
     * @method hasRealmRole
     * @param role {string} The role to check
     * @return {boolean} True if user in role.
     */


    hasRealmRole(role) {
      return this.keycloak.hasRealmRole(role);
    }
    /**
     * Delegates to the wrapped Keycloak instance's method.
     *
     * @method hasResourceRole
     * @param role {string} The role to check
     * @param resource {string} The resource to check
     * @return {boolean} True if user in role.
     */


    hasResourceRole(role, resource) {
      return this.keycloak.hasResourceRole(role, resource);
    }
    /**
     * Delegates to the wrapped Keycloak instance's method using the minValidity property.
     *
     * @method updateToken
     * @return {Promise}
     */


    updateToken() {
      return new Promise((resolve, reject) => {
        this.keycloak.updateToken(this.minValidity).success(refreshed => {
          resolve(refreshed);
        }).error(() => {
          console.debug('update token resolved as error');
          reject(new Error('authentication token update failed'));
        });
      });
    }
    /**
     * Delegates to the wrapped Keycloak instance's method.
     *
     * @method clearToken
     * @return {Promise} x
     */


    clearToken() {
      this.keycloak.clearToken();
    }
    /**
     * Updates the keycloak token redirecting to login page if not valid.
     *
     * @method checkTransition
     * @param {Transition} transition The transition in progress.
     * @return {Promise} Wrapped promise.
     */


    checkTransition(transition) {
      if (this.ready) {
        let url = this._parseRedirectUrl(this.router, transition); // console.debug(`Keycloak session :: checkTransition :: url='${url}'`);


        return this.updateToken().then(null, reason => {
          console.debug(`Keycloak session :: checkTransition :: update token failed reason='${reason}'. Will login with redirect='${url}'`);
          return this.login(url);
        });
      }
    }
    /**
     * Parses the redirect url from the intended 'to' route of a transition.
     *
     * @method _parseRedirectUrl
     * @param {RouterService} router The ember router service.
     * @param {Transition} transition The transition in progress.
     * @return {String} URL to include as the Keycloak redirect
     * @private
     */


    _parseRedirectUrl(router, transition) {
      let routeInfo = transition.to;
      let queryParams = routeInfo.queryParams;
      let params = [];
      routeInfo.find(info => info.paramNames.forEach(name => params.push(info.params[name]))); // console.debug(`Keycloak session :: _parseRedirectUrl :: '${routeInfo.name} ${JSON.stringify(params)} ${JSON.stringify(routeInfo.queryParams)}'`);
      //First check the intent for an explicit url

      let url = router.urlFor(routeInfo.name, ...params, {
        queryParams
      }); // console.debug(`Keycloak session :: _parseRedirectUrl :: ${window.location.origin} + ${router.rootUrl} + ${url}`);

      if (router.rootUrl) {
        return `${window.location.origin}${router.rootUrl}${url}`;
      }

      return `${window.location.origin}${url}`;
    }
    /**
     * Delegates to the wrapped Keycloak instance's method.
     *
     * @method loadUserProfile
     * @return {Promise} Resolves on server response
     */


    loadUserProfile() {
      this.keycloak.loadUserProfile().success(profile => {
        console.debug(`Loaded profile for ${profile.id}`);
        Ember.set(this, 'profile', profile);
      });
    }
    /**
     * Delegates to the wrapped Keycloak instance's method.
     *
     * @method login
     * @param {String} redirectUri Optional redirect url
     * @return {Promise} Resolves on server response
     */


    login(redirectUri) {
      let options = {
        redirectUri
      }; //Add idpHint to options, if it is populated

      if (this.get('idpHint')) {
        options['idpHint'] = this.get('idpHint');
      }

      console.debug(`Keycloak session :: login :: ${JSON.stringify(options)}`);
      return new Promise((resolve, reject) => {
        this.keycloak.login(options).success(() => {
          console.debug('Keycloak session :: login :: success');
          resolve('login OK');
        }).error(() => {
          console.debug('login error - this should never be possible');
          reject(new Error('login failed'));
        });
      });
    }
    /**
     * Delegates to the wrapped Keycloak instance's method.
     *
     * @method logout
     * @param {String} redirectUri Optional redirect url
     * @return {Promise} Resolves on server response.
     */


    logout(redirectUri) {
      let options = {
        redirectUri
      };
      console.debug(`Keycloak session :: logout :: ${JSON.stringify(options)}`);
      return new Promise((resolve, reject) => {
        this.keycloak.logout(options).success(() => {
          console.debug('Keycloak session :: logout :: success');
          this.keycloak.clearToken();
          resolve('logout OK');
        }).error(() => {
          console.debug('logout error - this should never be possible');
          this.keycloak.clearToken();
          reject(new Error('logout failed'));
        });
      });
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "keycloak", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "keycloak"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "subject", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "subject"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "refreshToken", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "refreshToken"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "token", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "token"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "tokenParsed", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "tokenParsed"), _class.prototype)), _class));
  _exports.default = KeycloakSessionService;
});