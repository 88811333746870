define('ember-paper-expansion-panel/components/paper-expansion-panel/expanded/header', ['exports', 'ember-paper-expansion-panel/templates/components/paper-expansion-panel/expanded/header'], function (exports, _header) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _header.default,
    tagName: ''
  });
});