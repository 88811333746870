define('@abcum/ember-helpers/helpers/currency', ['exports', '@abcum/ember-helpers/utils/symbol'], function (exports, _symbol) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.currency = currency;
	function currency(params) {
		return (0, _symbol.default)(params[0]);
	}

	exports.default = Ember.Helper.helper(currency);
});