define('@abcum/ember-helpers/mixins/invoke-action', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Mixin.create({

		init() {

			this._super(...arguments);

			if (this.get('invoke')) {
				this.on(this.get('eventName'), this, this.invoker);
			}
		},

		willDestroyElement() {

			this._super(...arguments);

			if (this.get('invoke')) {
				this.off(this.get('eventName'), this, this.invoker);
			}
		},

		invoker() {

			// eslint-disable-next-line ember/closure-actions
			this.sendAction('invoke');
		}

	});
});