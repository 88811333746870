define('@abcum/ember-helpers/helpers/uppercase', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.uppercase = uppercase;
	function uppercase([value = '']) {
		return String(value).toUpperCase();
	}

	exports.default = Ember.Helper.helper(uppercase);
});