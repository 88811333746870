define('@abcum/ember-helpers/helpers/split', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.split = split;
	function split([separator, ...params]) {
		return params.map(String).map(string => string.split(separator)).reduce((words, other) => words.concat(other));
	}

	exports.default = Ember.Helper.helper(split);
});