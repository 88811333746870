define("ember-models-table/templates/components/models-table/page-size-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wkg+ZHbg",
    "block": "{\"symbols\":[\"pss\",\"&default\"],\"statements\":[[4,\"with\",[[28,\"hash\",null,[[\"select\",\"themeInstance\",\"pageSizeOptions\",\"pageSize\"],[[28,\"component\",[[24,[\"themeInstance\",\"components\",\"select\"]]],[[\"options\",\"value\",\"type\",\"themeInstance\",\"class\"],[[24,[\"pageSizeOptions\"]],[24,[\"pageSize\"]],[24,[\"type\"]],[24,[\"themeInstance\"]],\"changePageSize\"]]],[24,[\"themeInstance\"]],[24,[\"pageSizeOptions\"]],[24,[\"pageSize\"]]]]]],null,{\"statements\":[[4,\"if\",[[25,2]],null,{\"statements\":[[0,\"    \"],[14,2,[[23,1,[]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"form\",true],[11,\"class\",[24,[\"themeInstance\",\"form\"]]],[8],[0,\"\\n      \"],[7,\"div\",true],[11,\"class\",[24,[\"themeInstance\",\"formElementWrapper\"]]],[8],[0,\"\\n        \"],[7,\"label\",true],[11,\"for\",[22,\"inputId\"]],[8],[1,[24,[\"themeInstance\",\"messages\",\"rowsCount\"]],false],[9],[0,\"\\n        \"],[1,[28,\"component\",[[23,1,[\"select\"]]],[[\"id\"],[[24,[\"inputId\"]]]]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/page-size-select.hbs"
    }
  });

  _exports.default = _default;
});