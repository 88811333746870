define('@abcum/ember-charts/components/chart-view', ['exports', 'chart.js'], function (exports, _chart) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		tagName: 'canvas',

		attributeBindings: ['width', 'height'],

		didInsertElement() {

			this._super(...arguments);

			let data = this.get('data');
			let type = this.get('type');
			let options = this.get('options');
			let element = this.get('element');

			this.chart = new _chart.default(element, {
				type, data, options
			});
		},

		willDestroyElement() {

			this._super(...arguments);

			this.get('chart').destroy();
		},

		didUpdateAttrs() {

			this._super(...arguments);

			let data = this.get('data');
			let chart = this.get('chart');
			let options = this.get('options');
			let animate = this.get('animate');

			if (chart) {
				chart.config.data = data;
				chart.config.options = options;
				animate ? chart.update() : chart.update(0);
			}
		}

	});
});