define('@abcum/ember-helpers/helpers/xor', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.xor = xor;
	function xor(params) {
		return params[0] && !params[1] || !params[0] && params[1];
	}

	exports.default = Ember.Helper.helper(xor);
});