define('@abcum/ember-helpers/helpers/is-active', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({

		router: Ember.inject.service(),

		compute([...params]) {
			return this.get('router').isActive(...params);
		}

	});
});