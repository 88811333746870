define('@abcum/ember-helpers/helpers/sort-locale-by', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({

		compute([...props], { numeric = false, caseFirst = 'false', sensitivity = 'base', ignorePunctuation = false }) {

			let param = props.slice(0, -1);
			let array = props.slice().pop();

			if (Ember.isArray(param[0]) || Ember.typeOf(param[0]) === 'function') {
				param = param[0];
			}

			this.set('param', param);
			this.set('array', array);

			this.set('confs', {
				numeric,
				caseFirst,
				sensitivity,
				ignorePunctuation
			});

			return this.get('content');
		},

		changed: Ember.observer('content', function () {
			this.recompute();
		}),

		sortDidChange: Ember.observer('param', 'confs', function () {

			let confs = Ember.get(this, 'confs');
			let param = Ember.get(this, 'param');

			if (Ember.isEmpty(param)) {
				Ember.defineProperty(this, 'content', []);
				return;
			}

			if (Ember.typeOf(param) === 'function') {
				Ember.defineProperty(this, 'content', []);
				return;
			}

			let props = param.slice().map(v => {
				return `array.@each.${String(v).split(':')[0]}`;
			});

			Ember.defineProperty(this, 'content', Ember.computed(...props, function () {
				return this.get('array').slice().sort(function (one, two) {

					for (let i = 0; i < param.length; i++) {

						let [prop, dir] = String(param[i]).split(':');

						let first = String(Ember.get(one, prop));
						let secnd = String(Ember.get(two, prop));

						let comps = first.localeCompare(secnd, undefined, {
							numeric: confs.numeric,
							caseFirst: confs.caseFirst,
							sensitivity: confs.sensitivity,
							ignorePunctuation: confs.ignorePunctuation
						});

						if (comps !== 0) {
							return dir === 'desc' ? -1 * comps : comps;
						} else {
							continue;
						}
					}
				});
			}));
		})

	});
});