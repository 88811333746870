define('@abcum/ember-helpers/helpers/pattern-phone', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.patternPhone = patternPhone;
	function patternPhone() {
		return '([+])?([0-9() ]+)';
	}

	exports.default = Ember.Helper.helper(patternPhone);
});