define('@abcum/ember-helpers/helpers/percent', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.percent = percent;
	function percent(params, hash = { style: 'percent' }) {
		let options = Object.assign({}, hash);
		options.style = 'percent';
		return Number(params[0]).toLocaleString([], options);
	}

	exports.default = Ember.Helper.helper(percent);
});