define('@abcum/ember-helpers/helpers/call', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({

		router: Ember.inject.service(),

		compute([method, ...params]) {
			let router = this.get('router');
			return (...passed) => {
				return router.send(method, ...params, ...passed);
			};
		}

	});
});