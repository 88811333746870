define('@abcum/ember-helpers/helpers/length', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({

		changed: Ember.observer('array.[]', function () {
			this.recompute();
		}),

		compute([array]) {
			this.set('array', array);
			return this.get('array.length');
		}

	});
});