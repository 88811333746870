define('@abcum/ember-helpers/helpers/throttle', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.throttle = throttle;
	function throttle([callback, wait], { immediate = false }) {
		return function (value) {
			return Ember.run.throttle(null, callback, value, wait, immediate);
		};
	}

	exports.default = Ember.Helper.helper(throttle);
});