define('@abcum/ember-helpers/helpers/ucwords', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.ucwords = ucwords;
	function ucwords([value = '']) {
		return String(value).split(' ').map(word => {
			return word.charAt(0).toUpperCase() + word.substring(1);
		}).join(' ');
	}

	exports.default = Ember.Helper.helper(ucwords);
});