define('@abcum/ember-helpers/helpers/ne', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.ne = ne;
	function ne(params) {
		return params[0] !== params[1];
	}

	exports.default = Ember.Helper.helper(ne);
});