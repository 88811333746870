define('@abcum/ember-helpers/helpers/drop-effect', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.dropEffect = dropEffect;
	function dropEffect(effect = 'move') {
		return function (event) {
			event.dataTransfer.dropEffect = effect;
			return event;
		};
	}

	exports.default = Ember.Helper.helper(dropEffect);
});