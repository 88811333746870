define('@abcum/ember-helpers/components/scroll-block', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		scroller: Ember.inject.service(),

		didInsertElement() {
			this._super(...arguments);
			let key = this.get('key');
			let pos = this.get('scroller')[key] || 0;
			this.$().scrollTop(pos);
		},

		willDestroyElement() {
			this._super(...arguments);
			let key = this.get('key');
			let pos = this.$().scrollTop();
			this.get('scroller')[key] = pos;
		}

	});
});