define('@abcum/ember-helpers/helpers/pattern-numbers', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.patternNumbers = patternNumbers;
	function patternNumbers() {
		return '[0-9]+';
	}

	exports.default = Ember.Helper.helper(patternNumbers);
});