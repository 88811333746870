define('@abcum/ember-helpers/helpers/pattern-twitter', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.patternTwitter = patternTwitter;
	function patternTwitter() {
		return '[a-zA-Z0-9_]+';
	}

	exports.default = Ember.Helper.helper(patternTwitter);
});