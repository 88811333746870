define('@abcum/ember-helpers/helpers/join', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({

		changed: Ember.observer('array.[]', function () {
			this.recompute();
		}),

		compute([separator, ...array]) {
			this.set('array', array);
			return Ember.A(array).reduce((words, other) => words.concat(other)).join(separator);
		}

	});
});