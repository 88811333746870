define('@abcum/ember-helpers/utils/country2', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = country2;
	function country2(code) {
		switch (code) {
			case 'AF':
				return 'Afganistan';
			case 'AX':
				return 'Åland Islands';
			case 'AL':
				return 'Albania';
			case 'DZ':
				return 'Algeria';
			case 'AS':
				return 'American Samoa';
			case 'AD':
				return 'Andorra';
			case 'AO':
				return 'Angola';
			case 'AI':
				return 'Anguilla';
			case 'AG':
				return 'Antigua & Barbuda';
			case 'AR':
				return 'Argentina';
			case 'AM':
				return 'Armenia';
			case 'AW':
				return 'Aruba';
			case 'AU':
				return 'Australia';
			case 'AT':
				return 'Austria';
			case 'AZ':
				return 'Azerbaijan';
			case 'BS':
				return 'Bahamas';
			case 'BH':
				return 'Bahrain';
			case 'BD':
				return 'Bangladesh';
			case 'BB':
				return 'Barbados';
			case 'BY':
				return 'Belarus';
			case 'BE':
				return 'Belgium';
			case 'BZ':
				return 'Belize';
			case 'BJ':
				return 'Benin';
			case 'BM':
				return 'Bermuda';
			case 'BT':
				return 'Bhutan';
			case 'BO':
				return 'Bolivia';
			case 'BQ':
				return 'Bonaire';
			case 'BA':
				return 'Bosnia-Herzegovina';
			case 'BW':
				return 'Botswana';
			case 'BR':
				return 'Brazil';
			case 'IO':
				return 'British Indian Ocean Territory';
			case 'VG':
				return 'British Virgin Islands';
			case 'BN':
				return 'Brunei Darussalam';
			case 'BG':
				return 'Bulgaria';
			case 'BF':
				return 'Burkina Faso';
			case 'BI':
				return 'Burundi';
			case 'KH':
				return 'Cambodia';
			case 'CM':
				return 'Cameroon';
			case 'CA':
				return 'Canada';
			case 'CV':
				return 'Cape Verde Islands';
			case 'KY':
				return 'Cayman Islands';
			case 'CF':
				return 'Central African Republic';
			case 'TD':
				return 'Chad';
			case 'CL':
				return 'Chile';
			case 'CN':
				return 'China';
			case 'CX':
				return 'Christmas Island';
			case 'CC':
				return 'Cocos (Keeling) Islands';
			case 'CO':
				return 'Colombia';
			case 'KM':
				return 'Comoros';
			case 'CG':
				return 'Congo (Brazzaville)';
			case 'CD':
				return 'Congo (Kinshasa)';
			case 'CK':
				return 'Cook Islands';
			case 'CR':
				return 'Costa Rica';
			case 'HR':
				return 'Croatia';
			case 'CU':
				return 'Cuba';
			case 'CW':
				return 'Curaçao';
			case 'CY':
				return 'Cyprus';
			case 'CZ':
				return 'Czech Republic';
			case 'DK':
				return 'Denmark';
			case 'DJ':
				return 'Djibouti';
			case 'DM':
				return 'Dominica';
			case 'DO':
				return 'Dominican Republic';
			case 'TL':
				return 'East Timor';
			case 'EC':
				return 'Ecuador';
			case 'EG':
				return 'Egypt';
			case 'SV':
				return 'El Salvador';
			case 'GQ':
				return 'Equatorial Guinea';
			case 'ER':
				return 'Eritrea';
			case 'EE':
				return 'Estonia';
			case 'ET':
				return 'Ethiopia';
			case 'FO':
				return 'Faeroe Islands';
			case 'FK':
				return 'Falkland Islands';
			case 'FJ':
				return 'Fiji';
			case 'FI':
				return 'Finland';
			case 'FR':
				return 'France';
			case 'GF':
				return 'French Guiana';
			case 'PF':
				return 'French Polynesia';
			case 'TF':
				return 'French Southern Territories';
			case 'GA':
				return 'Gabon';
			case 'GM':
				return 'Gambia';
			case 'GE':
				return 'Georgia';
			case 'DE':
				return 'Germany';
			case 'GH':
				return 'Ghana';
			case 'GI':
				return 'Gibralter';
			case 'GR':
				return 'Greece';
			case 'GL':
				return 'Greenland';
			case 'GD':
				return 'Grenada';
			case 'GP':
				return 'Guadeloupe';
			case 'GU':
				return 'Guam';
			case 'GT':
				return 'Guatemala';
			case 'GG':
				return 'Guernsey';
			case 'GN':
				return 'Guinea';
			case 'GW':
				return 'Guinea-Bissau';
			case 'GY':
				return 'Guyana';
			case 'HT':
				return 'Haiti';
			case 'VA':
				return 'Holy See';
			case 'HN':
				return 'Honduras';
			case 'HK':
				return 'Hong Kong';
			case 'HU':
				return 'Hungary';
			case 'IS':
				return 'Iceland';
			case 'IN':
				return 'India';
			case 'ID':
				return 'Indonesia';
			case 'IR':
				return 'Iran';
			case 'IQ':
				return 'Iraq';
			case 'IE':
				return 'Ireland';
			case 'IM':
				return 'Isle of Man';
			case 'IL':
				return 'Israel';
			case 'IT':
				return 'Italy';
			case 'CI':
				return 'Ivory Coast';
			case 'JM':
				return 'Jamaica';
			case 'JP':
				return 'Japan';
			case 'JE':
				return 'Jersey';
			case 'JO':
				return 'Jordan';
			case 'KZ':
				return 'Kazakhstan';
			case 'KE':
				return 'Kenya';
			case 'KI':
				return 'Kiribati';
			case 'KW':
				return 'Kuwait';
			case 'KG':
				return 'Kyrgyzstan';
			case 'LA':
				return 'Laos';
			case 'LV':
				return 'Latvia';
			case 'LB':
				return 'Lebanon';
			case 'LS':
				return 'Lesotho';
			case 'LR':
				return 'Liberia';
			case 'LY':
				return 'Libya';
			case 'LI':
				return 'Liechtenstein';
			case 'LT':
				return 'Lithuania';
			case 'LU':
				return 'Luxembourg';
			case 'MO':
				return 'Macau';
			case 'MK':
				return 'Macedonia';
			case 'MG':
				return 'Madagascar';
			case 'MW':
				return 'Malawi';
			case 'MY':
				return 'Malaysia';
			case 'MV':
				return 'Maldives';
			case 'ML':
				return 'Mali';
			case 'MT':
				return 'Malta';
			case 'MH':
				return 'Marshall Islands';
			case 'MQ':
				return 'Martinique';
			case 'MR':
				return 'Mauritania';
			case 'MU':
				return 'Mauritius';
			case 'YT':
				return 'Mayotte';
			case 'MX':
				return 'Mexico';
			case 'FM':
				return 'Micronesia';
			case 'MD':
				return 'Moldova';
			case 'MC':
				return 'Monaco';
			case 'MN':
				return 'Mongolia';
			case 'ME':
				return 'Montenegro';
			case 'MS':
				return 'Montserrat';
			case 'MA':
				return 'Morocco';
			case 'MZ':
				return 'Mozambique';
			case 'MM':
				return 'Myanmar';
			case 'NA':
				return 'Namibia';
			case 'NR':
				return 'Nauru';
			case 'NP':
				return 'Nepal';
			case 'AN':
				return 'Netherlands Antilles';
			case 'NL':
				return 'Netherlands';
			case 'NC':
				return 'New Caledonia';
			case 'NZ':
				return 'New Zealand';
			case 'NI':
				return 'Nicaragua';
			case 'NE':
				return 'Niger';
			case 'NG':
				return 'Nigeria';
			case 'NU':
				return 'Niue';
			case 'NF':
				return 'Norfolk Island';
			case 'KP':
				return 'North Korea';
			case 'MP':
				return 'Northern Mariana Islands';
			case 'NO':
				return 'Norway';
			case 'OM':
				return 'Oman';
			case 'PK':
				return 'Pakistan';
			case 'PW':
				return 'Palau';
			case 'PS':
				return 'Palestinian Territories';
			case 'PA':
				return 'Panama';
			case 'PG':
				return 'Papua New Guinea';
			case 'PY':
				return 'Paraguay';
			case 'PE':
				return 'Peru';
			case 'PH':
				return 'Philippines';
			case 'PN':
				return 'Pitcairn Islands';
			case 'PL':
				return 'Poland';
			case 'PT':
				return 'Portugal';
			case 'PR':
				return 'Puerto Rico';
			case 'QA':
				return 'Qatar';
			case 'RO':
				return 'Romania';
			case 'RU':
				return 'Russia';
			case 'RW':
				return 'Rwanda';
			case 'RE':
				return 'Réunion';
			case 'BL':
				return 'Saint Barthélemy';
			case 'KN':
				return 'Saint Christopher & Nevis';
			case 'SH':
				return 'Saint Helena';
			case 'LC':
				return 'Saint Lucia';
			case 'MF':
				return 'Saint Martin';
			case 'PM':
				return 'Saint Pierre & Miquelon';
			case 'VC':
				return 'Saint Vincent & The Grenadines';
			case 'WS':
				return 'Samoa';
			case 'SM':
				return 'San Marino';
			case 'ST':
				return 'Sao Tome & Principe';
			case 'SA':
				return 'Saudi Arabia';
			case 'SN':
				return 'Senegal';
			case 'RS':
				return 'Serbia';
			case 'SC':
				return 'Seychelles';
			case 'SL':
				return 'Sierra Leone';
			case 'SG':
				return 'Singapore';
			case 'SX':
				return 'Sint Maarten';
			case 'SK':
				return 'Slovakia';
			case 'SI':
				return 'Slovenia';
			case 'SB':
				return 'Solomon Islands';
			case 'SO':
				return 'Somalia';
			case 'ZA':
				return 'South Africa';
			case 'GS':
				return 'South Georgia & The South Sandwish Islands';
			case 'KR':
				return 'South Korea';
			case 'SS':
				return 'South Sudan';
			case 'ES':
				return 'Spain';
			case 'LK':
				return 'Sri Lanka';
			case 'SD':
				return 'Sudan';
			case 'SR':
				return 'Suriname';
			case 'SZ':
				return 'Swaziland';
			case 'SE':
				return 'Sweden';
			case 'CH':
				return 'Switzerland';
			case 'SY':
				return 'Syria';
			case 'TW':
				return 'Taiwan';
			case 'TJ':
				return 'Tajikistan';
			case 'TZ':
				return 'Tanzania';
			case 'TH':
				return 'Thailand';
			case 'TG':
				return 'Togo';
			case 'TK':
				return 'Tokelau';
			case 'TO':
				return 'Tonga';
			case 'TT':
				return 'Trinidad & Tobago';
			case 'TN':
				return 'Tunisia';
			case 'TR':
				return 'Turkey';
			case 'TM':
				return 'Turkmenistan';
			case 'TC':
				return 'Turks & Caicos Islands';
			case 'TV':
				return 'Tuvalu';
			case 'UG':
				return 'Uganda';
			case 'UA':
				return 'Ukraine';
			case 'AE':
				return 'United Arab Emirates';
			case 'GB':
				return 'United Kingdom';
			case 'US':
				return 'United States';
			case 'VI':
				return 'United States Virgin Islands';
			case 'UY':
				return 'Uruguay';
			case 'UZ':
				return 'Uzbekistan';
			case 'VU':
				return 'Vanuatu';
			case 'VE':
				return 'Venezuela';
			case 'VN':
				return 'Vietnam';
			case 'WF':
				return 'Wallis & Futuna';
			case 'EH':
				return 'Western Sahara';
			case 'YE':
				return 'Yemen';
			case 'ZM':
				return 'Zambia';
			case 'ZW':
				return 'Zimbabwe';
			default:
				return undefined;
		}
	}
});