define('@abcum/ember-helpers/helpers/range', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.range = range;
	function range([min, max]) {

		let numbers = [];

		if (min < max) {
			for (let i = min; i <= max; i++) {
				numbers.push(i);
			}
		}

		if (max < min) {
			for (let i = max; i >= min; i++) {
				numbers.push(i);
			}
		}

		return numbers;
	}

	exports.default = Ember.Helper.helper(range);
});