define('@abcum/ember-helpers/utils/is-promise', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = isPromise;
	function isPromise(val) {
		return Ember.typeOf(val) === 'object' && Ember.typeOf(val.then) === 'function' && Ember.typeOf(val.catch) === 'function';
	}
});