define('@abcum/ember-helpers/helpers/ceil', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.ceil = ceil;
	function ceil(params) {
		return Math.ceil(params[0]);
	}

	exports.default = Ember.Helper.helper(ceil);
});