define("ember-vertical-timeline/components/timeline/timeline-content", ["exports", "ember-in-viewport", "ember-vertical-timeline/templates/components/timeline/timeline-content"], function (_exports, _emberInViewport, _timelineContent) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_emberInViewport.default, {
    layout: _timelineContent.default,
    animate: true,
    classNames: 'timeline-content',
    classNameBindings: ['bounceIn:bounce-in:is-hidden', 'animate'],

    didEnterViewport() {
      this.set('bounceIn', true);
    }

  });

  _exports.default = _default;
});