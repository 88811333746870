define('@abcum/ember-helpers/helpers/min', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.min = min;
	function min(params) {
		return Math.min(...params);
	}

	exports.default = Ember.Helper.helper(min);
});