define('@abcum/ember-helpers/helpers/begs-with', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.begsWith = begsWith;
	function begsWith([str, prefix]) {
		return String(str).indexOf(String(prefix)) === 0;
	}

	exports.default = Ember.Helper.helper(begsWith);
});