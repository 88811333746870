define('@abcum/ember-helpers/helpers/sanitize', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.sanitize = sanitize;
	function sanitize([value]) {
		return Ember.String.htmlSafe(value);
	}

	exports.default = Ember.Helper.helper(sanitize);
});