define('@abcum/ember-helpers/helpers/or', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.or = or;
	function or(params) {
		return params.some(p => !!p);
	}

	exports.default = Ember.Helper.helper(or);
});