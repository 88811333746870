define('@abcum/ember-helpers/helpers/mult', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.mult = mult;
	function mult(params) {
		return params.reduce((a, b) => a * b);
	}

	exports.default = Ember.Helper.helper(mult);
});