define('@abcum/ember-helpers/helpers/lcwords', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.lcwords = lcwords;
	function lcwords([value = '']) {
		return String(value).split(' ').map(word => {
			return word.charAt(0).toLowerCase() + word.substring(1);
		}).join(' ');
	}

	exports.default = Ember.Helper.helper(lcwords);
});