define("ember-paper/components/paper-input", ["exports", "ember-paper/templates/components/paper-input", "ember-paper/mixins/focusable-mixin", "ember-paper/mixins/color-mixin", "ember-paper/mixins/child-mixin", "ember-paper/mixins/validation-mixin", "ember-invoke-action"], function (_exports, _paperInput, _focusableMixin, _colorMixin, _childMixin, _validationMixin, _emberInvokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * @class PaperInput
   * @extends Ember.Component
   * @uses FocusableMixin
   * @uses ChildMixin
   * @uses ColorMixin
   * @uses ValidationMixin
   */
  var _default = Ember.Component.extend(_focusableMixin.default, _colorMixin.default, _childMixin.default, _validationMixin.default, {
    layout: _paperInput.default,
    tagName: 'md-input-container',
    classNames: ['md-default-theme'],
    classNameBindings: ['hasValue:md-input-has-value', 'isInvalidAndTouched:md-input-invalid', 'hasLeftIcon:md-icon-left', 'hasRightIcon:md-icon-right', 'focused:md-input-focused', 'block:md-block', 'placeholder:md-input-has-placeholder'],
    type: 'text',
    autofocus: false,
    tabindex: null,
    hideAllMessages: false,
    isTouched: false,
    iconComponent: 'paper-icon',
    // override validation mixin `isInvalid` to account for the native input validity
    isInvalid: Ember.computed.or('hasErrorMessages', 'isNativeInvalid'),
    hasValue: Ember.computed('value', 'isNativeInvalid', function () {
      let value = this.get('value');
      let isNativeInvalid = this.get('isNativeInvalid');
      return !Ember.isEmpty(value) || isNativeInvalid;
    }),
    shouldAddPlaceholder: Ember.computed('label', 'focused', function () {
      // if has label, only add placeholder when focused
      return Ember.isEmpty(this.get('label')) || this.get('focused');
    }),
    inputElementId: Ember.computed('elementId', {
      get() {
        return `input-${this.get('elementId')}`;
      },

      // elementId can be set from outside and it will override the computed value.
      // Please check the deprecations for further details
      // https://deprecations.emberjs.com/v3.x/#toc_computed-property-override
      set(key, value) {
        // To make sure the context updates properly, We are manually set value using @ember/object#set as recommended.
        return Ember.set(this, "elementId", value);
      }

    }),
    renderCharCount: Ember.computed('value', function () {
      let currentLength = this.get('value') ? this.get('value').length : 0;
      return `${currentLength}/${this.get('maxlength')}`;
    }),
    hasLeftIcon: Ember.computed.bool('icon'),
    hasRightIcon: Ember.computed.bool('iconRight'),
    isInvalidAndTouched: Ember.computed.and('isInvalid', 'isTouched'),
    validationProperty: 'value',

    // property that validations should be run on
    // Lifecycle hooks
    didReceiveAttrs() {
      this._super(...arguments);

      (true && !(this.get('onChange') !== undefined) && Ember.assert('{{paper-input}} requires an `onChange` action or null for no action.', this.get('onChange') !== undefined));
      let {
        value,
        errors
      } = this.getProperties('value', 'errors');
      let {
        _prevValue,
        _prevErrors
      } = this.getProperties('_prevValue', '_prevErrors');

      if (value !== _prevValue || errors !== _prevErrors) {
        this.notifyValidityChange();
      }

      this._prevValue = value;
      this._prevErrors = errors;
    },

    didInsertElement() {
      this._super(...arguments);

      if (this.get('textarea')) {
        this._growTextareaOnResize = Ember.run.bind(this, this.growTextarea);
        window.addEventListener('resize', this._growTextareaOnResize);
      }
    },

    didRender() {
      this._super(...arguments); // setValue below ensures that the input value is the same as this.value


      this.setValue(this.get('value'));
      this.growTextarea();
    },

    willDestroyElement() {
      this._super(...arguments);

      if (this.get('textarea')) {
        window.removeEventListener('resize', this._growTextareaOnResize);
        this._growTextareaOnResize = null;
      }
    },

    growTextarea() {
      if (this.get('textarea')) {
        let inputElement = this.element.querySelector('input, textarea');
        inputElement.classList.add('md-no-flex');
        inputElement.setAttribute('rows', 1);
        let minRows = this.get('passThru.rows');
        let height = this.getHeight(inputElement);

        if (minRows) {
          if (!this.lineHeight) {
            inputElement.style.minHeight = 0;
            this.lineHeight = inputElement.clientHeight;
            inputElement.style.minHeight = null;
          }

          if (this.lineHeight) {
            height = Math.max(height, this.lineHeight * minRows);
          }

          let proposedHeight = Math.round(height / this.lineHeight);
          let maxRows = this.get('passThru.maxRows') || Number.MAX_VALUE;
          let rowsToSet = Math.min(proposedHeight, maxRows);
          inputElement.style.height = `${this.lineHeight * rowsToSet}px`;
          inputElement.setAttribute('rows', rowsToSet);

          if (proposedHeight >= maxRows) {
            inputElement.classList.add('md-textarea-scrollable');
          } else {
            inputElement.classList.remove('md-textarea-scrollable');
          }
        } else {
          inputElement.style.height = 'auto';
          inputElement.scrollTop = 0;
          let height = this.getHeight(inputElement);

          if (height) {
            inputElement.style.height = `${height}px`;
          }
        }

        inputElement.classList.remove('md-no-flex');
      }
    },

    getHeight(inputElement) {
      let {
        offsetHeight
      } = inputElement;
      let line = inputElement.scrollHeight - offsetHeight;
      return offsetHeight + (line > 0 ? line : 0);
    },

    setValue(value) {
      // normalize falsy values to empty string
      value = Ember.isEmpty(value) ? '' : value;

      if (this.element.querySelector('input, textarea').value !== value) {
        this.element.querySelector('input, textarea').value = value;
      }
    },

    actions: {
      handleInput(e) {
        (0, _emberInvokeAction.invokeAction)(this, 'onChange', e.target.value); // setValue below ensures that the input value is the same as this.value

        Ember.run.next(() => {
          if (this.isDestroyed) {
            return;
          }

          this.setValue(this.get('value'));
        });
        this.growTextarea();
        let inputElement = this.element.querySelector('input');
        let isNativeInvalid = inputElement && inputElement.validity && inputElement.validity.badInput;

        if (this.type === 'date' && e.target.value === '') {
          // Chrome doesn't fire the onInput event when clearing the second and third date components.
          // This means that we won't see another event when badInput becomes false if the user is clearing
          // the date field.  The reported value is empty, though, so we can already mark it as valid.
          isNativeInvalid = false;
        }

        this.set('isNativeInvalid', isNativeInvalid);
        this.notifyValidityChange();
      },

      handleBlur(e) {
        (0, _emberInvokeAction.invokeAction)(this, 'onBlur', e);
        this.set('isTouched', true);
        this.notifyValidityChange();
      }

    }
  });

  _exports.default = _default;
});