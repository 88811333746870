define('@abcum/ember-helpers/initializers/helpers', ['exports', '@abcum/ember-helpers/mixins/invoke-action'], function (exports, _invokeAction) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});

	exports.default = function () {

		Ember.LinkComponent.reopen(_invokeAction.default);
	};
});