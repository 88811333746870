define('@abcum/ember-helpers/helpers/is', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.is = is;
	function is(params) {
		return params.every(p => p === true);
	}

	exports.default = Ember.Helper.helper(is);
});