define("ember-paper/components/paper-divider/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eaHk2Wky",
    "block": "{\"symbols\":[\"@inset\",\"&attrs\"],\"statements\":[[7,\"md-divider\",false],[12,\"class\",\"md-default-theme\"],[12,\"md-inset\",[23,1,[]]],[13,2],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/components/paper-divider/template.hbs"
    }
  });

  _exports.default = _default;
});