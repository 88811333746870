define('@abcum/ember-helpers/helpers/gt', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.gt = gt;
	function gt(params) {
		return params[0] > params[1];
	}

	exports.default = Ember.Helper.helper(gt);
});