define('@abcum/ember-helpers/helpers/nl2br', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.nl2br = nl2br;
	function nl2br([value = '']) {
		return Ember.String.htmlSafe(String(value).replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2'));
	}

	exports.default = Ember.Helper.helper(nl2br);
});