define('@abcum/ember-helpers/helpers/abs', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({
		compute([value = 0]) {
			return Math.abs(value);
		}
	});
});