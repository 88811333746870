define("ember-google-charts/utils/render-classic-chart", ["exports", "ember-google-charts/utils/render-chart"], function (_exports, _renderChart) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = renderClassicChart;

  function renderClassicChart(data, options) {
    (true && Ember.warn(`renderClassicChart() has been deprecated. Use renderChart() instead. See https://github.com/sir-dunxalot/ember-google-charts#custom-charts`, {
      id: 'ember-google-charts.unified-render-util'
    }));
    const {
      design,
      element,
      type
    } = this;
    return (0, _renderChart.default)(element, {
      data,
      design,
      options,
      type
    });
  }
});