define("ember-vertical-timeline/components/timeline/timeline-block", ["exports", "ember-vertical-timeline/templates/components/timeline/timeline-block"], function (_exports, _timelineBlock) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    layout: _timelineBlock.default,
    classNames: 'timeline-block',
    animate: true
  });

  _exports.default = _default;
});