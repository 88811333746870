define('@abcum/ember-helpers/helpers/convert', ['exports', '@abcum/ember-helpers/utils/currency'], function (exports, _currency) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.convert = convert;
	function convert(params, hash) {

		let t = hash.to || 'USD';
		let f = hash.from || 'USD';
		let a = parseFloat(params[0]);

		if (f === window.fxbase || 'USD') {
			return a * (0, _currency.default)(t);
		}

		if (t === window.fxbase || 'USD') {
			return a * (1 / (0, _currency.default)(f));
		}

		return a * ((0, _currency.default)(t) * (1 / (0, _currency.default)(f)));
	}

	exports.default = Ember.Helper.helper(convert);
});