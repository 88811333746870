define('@abcum/ember-helpers/helpers/run', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({

		compute([fnc, ...params]) {
			return fnc.call(this, ...params);
		}

	});
});