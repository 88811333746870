define("ember-models-table/templates/components/models-table/themes/ember-semanticui/select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YZ7Y2fwM",
    "block": "{\"symbols\":[\"execute\",\"mapper\",\"opt\",\"&default\"],\"statements\":[[4,\"ui-dropdown\",null,[[\"class\",\"selected\",\"placeholder\",\"onChange\"],[[28,\"concat\",[\"selection \",[28,\"unless\",[[24,[\"wide\"]],\"compact\"],null],[28,\"unless\",[[24,[\"options\",\"length\"]],\" disabled\"],null]],null],[24,[\"value\"]],[24,[\"column\",\"filterPlaceholder\"]],[28,\"action\",[[23,0,[]],\"updateValue\"],null]]],{\"statements\":[[0,\"  \"],[7,\"i\",true],[10,\"class\",\"dropdown icon\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"default text\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"menu\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"options\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[11,\"data-id\",[28,\"stringify\",[[23,3,[\"value\"]]],null]],[11,\"data-value\",[28,\"map-value\",[[23,2,[]],[23,3,[\"value\"]]],null]],[10,\"class\",\"item\"],[8],[0,\"\\n        \"],[1,[23,3,[\"label\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[3]},null],[0,\"  \"],[9],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[28,\"not-eq\",[[24,[\"value\"]],\"\"],null],[24,[\"clearable\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"i\",true],[11,\"class\",[29,[\"clearFilterIcon \",[24,[\"themeInstance\",\"clearFilterIcon\"]]]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"value\"]]],null],\"\"],null]],[8],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null],[0,\"\\n\"],[14,4]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/themes/ember-semanticui/select.hbs"
    }
  });

  _exports.default = _default;
});