define("ember-models-table/templates/components/models-table/cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "b5rmUIPw",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1,[[28,\"hash\",null,[[\"record\",\"index\",\"column\",\"componentToRender\",\"expandRow\",\"collapseRow\",\"expandAllRows\",\"collapseAllRows\",\"editRow\",\"cancelEditRow\",\"themeInstance\",\"clickOnRow\",\"isExpanded\",\"isSelected\",\"isEditRow\",\"saveRow\",\"isColumnEditable\"],[[24,[\"record\"]],[24,[\"index\"]],[24,[\"column\"]],[24,[\"componentToRender\"]],[24,[\"expandRow\"]],[24,[\"collapseRow\"]],[24,[\"expandAllRows\"]],[24,[\"collapseAllRows\"]],[24,[\"editRow\"]],[24,[\"cancelEditRow\"]],[24,[\"themeInstance\"]],[24,[\"clickOnRow\"]],[24,[\"isExpanded\"]],[24,[\"isSelected\"]],[24,[\"isEditRow\"]],[24,[\"saveRow\"]],[24,[\"isColumnEditable\"]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"componentToRender\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[24,[\"componentToRender\"]]],[[\"record\",\"index\",\"column\",\"isEditRow\",\"editRow\",\"saveRow\",\"cancelEditRow\",\"expandRow\",\"collapseRow\",\"expandAllRows\",\"collapseAllRows\",\"clickOnRow\",\"isExpanded\",\"isSelected\",\"isColumnEditable\",\"themeInstance\"],[[24,[\"record\"]],[24,[\"index\"]],[24,[\"column\"]],[24,[\"isEditRow\"]],[24,[\"editRow\"]],[24,[\"saveRow\"]],[24,[\"cancelEditRow\"]],[24,[\"expandRow\"]],[24,[\"collapseRow\"]],[24,[\"expandAllRows\"]],[24,[\"collapseAllRows\"]],[24,[\"clickOnRow\"]],[24,[\"isExpanded\"]],[24,[\"isSelected\"]],[24,[\"isColumnEditable\"]],[24,[\"themeInstance\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/cell.hbs"
    }
  });

  _exports.default = _default;
});