define('@abcum/ember-helpers/helpers/lowercase', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.lowercase = lowercase;
	function lowercase([value = '']) {
		return String(value).toLowerCase();
	}

	exports.default = Ember.Helper.helper(lowercase);
});