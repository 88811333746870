define('ember-paper-expansion-panel/components/paper-expansion-panel/expanded/footer', ['exports', 'ember-paper-expansion-panel/templates/components/paper-expansion-panel/expanded/footer'], function (exports, _footer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _footer.default,
    tagName: ''
  });
});