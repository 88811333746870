define('@abcum/ember-helpers/helpers/is-updating', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({

		changed: Ember.observer('object.isUpdating', function () {
			this.recompute();
		}),

		compute([object]) {
			this.set('object', object);
			return this.get('object.isUpdating');
		}

	});
});