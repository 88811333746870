define('@abcum/ember-helpers/helpers/floor', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.floor = floor;
	function floor(params) {
		return Math.floor(params[0]);
	}

	exports.default = Ember.Helper.helper(floor);
});