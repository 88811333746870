define('@abcum/ember-helpers/helpers/ignore', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({

		compute([fnc]) {
			return () => fnc.call(this);
		}

	});
});