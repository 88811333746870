define('@abcum/ember-helpers/helpers/underscore', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.underscore = underscore;
	function underscore([value = '']) {
		return Ember.String.underscore(String(value));
	}

	exports.default = Ember.Helper.helper(underscore);
});