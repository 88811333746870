define('@abcum/ember-helpers/helpers/dec', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.dec = dec;
	function dec([value, amount]) {
		return parseFloat(value) - (parseFloat(amount) || 1);
	}

	exports.default = Ember.Helper.helper(dec);
});