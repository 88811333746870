define('@abcum/ember-helpers/helpers/pattern-currency', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.patternCurrency = patternCurrency;
	function patternCurrency() {
		return '[A-Z]{3}';
	}

	exports.default = Ember.Helper.helper(patternCurrency);
});