define("ember-models-table/helpers/and", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.and = and;
  _exports.default = void 0;

  function and(params
  /*, hash*/
  ) {
    for (let i = 0, len = params.length; i < len; i++) {
      if (!params[i]) {
        return false;
      }
    }

    return true;
  }

  var _default = Ember.Helper.helper(and);

  _exports.default = _default;
});