define('ember-paper-expansion-panel/components/paper-expansion-panel/collapsed', ['exports', 'ember-paper-expansion-panel/templates/components/paper-expansion-panel/collapsed'], function (exports, _collapsed) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _collapsed.default,
    tagName: ''
  });
});