define('@abcum/ember-helpers/helpers/natural-sort', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({

		compute([param], { numeric = false, caseFirst = 'false', sensitivity = 'base', ignorePunctuation = false }) {
			return function (one, two) {

				let [prop, dir] = String(param).split(':');

				let first = String(Ember.get(one, prop));
				let secnd = String(Ember.get(two, prop));

				let comps = first.localeCompare(secnd, undefined, {
					numeric,
					caseFirst,
					sensitivity,
					ignorePunctuation
				});

				return dir === 'desc' ? -1 * comps : comps;
			};
		}

	});
});