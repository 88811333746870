define('@abcum/ember-helpers/helpers/number', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.number = number;
	function number(params, hash = { style: 'decimal' }) {
		let options = Object.assign({}, hash);
		options.style = 'decimal';
		return Number(params[0]).toLocaleString([], options);
	}

	exports.default = Ember.Helper.helper(number);
});