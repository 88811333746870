define('@abcum/ember-helpers/helpers/present', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.present = present;
	function present(params) {
		return params.every(p => Ember.isPresent(p));
	}

	exports.default = Ember.Helper.helper(present);
});