define('@abcum/ember-helpers/helpers/money', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.money = money;
	function money(params, hash = { style: 'currency', currency: 'USD' }) {
		let options = Object.assign({}, hash);
		options.style = 'currency';
		options.currency = options.currency || 'USD';
		return Number(params[0]).toLocaleString([], options);
	}

	exports.default = Ember.Helper.helper(money);
});