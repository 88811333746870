define('@abcum/ember-helpers/helpers/sub', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.sub = sub;
	function sub(params) {
		return params.reduce((a, b) => a - b);
	}

	exports.default = Ember.Helper.helper(sub);
});