define('@abcum/ember-helpers/helpers/first', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({

		changed: Ember.observer('array.[]', function () {
			this.recompute();
		}),

		compute([array]) {

			if (!Ember.isArray(array)) {
				return undefined;
			}

			this.set('array', array);

			return Ember.A(array).objectAt(0);
		}

	});
});