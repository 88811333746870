define("ember-composable-helpers/helpers/without", ["exports", "ember-composable-helpers/utils/includes"], function (_exports, _includes) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.without = without;
  _exports.default = void 0;

  function contains(needle, haystack) {
    return (0, _includes.default)(Ember.A(haystack), needle);
  }

  function without(needle, haystack) {
    if (!Ember.isArray(haystack)) {
      return false;
    }

    if (Ember.isArray(needle) && needle.length) {
      return haystack.reduce((acc, val) => {
        return contains(val, needle) ? acc : acc.concat(val);
      }, []);
    }

    return Ember.A(haystack).without(needle);
  }

  var _default = Ember.Helper.helper(function ([needle, haystack]) {
    return without(needle, haystack);
  });

  _exports.default = _default;
});