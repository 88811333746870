define('@abcum/ember-helpers/helpers/reload', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({

		compute([...main]) {
			return function (...rest) {
				window.location.reload(main.join('') + rest.join(''));
				return;
			};
		}

	});
});