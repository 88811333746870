define('@abcum/ember-helpers/helpers/invoke', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.invoke = invoke;
	function invoke([method, object, ...args]) {

		if (Ember.isArray(object)) {
			return function () {
				return Ember.RSVP.all(object.map(i => Ember.tryInvoke(i, method, args)));
			};
		}

		return function () {
			return Ember.tryInvoke(object, method, args);
		};
	}

	exports.default = Ember.Helper.helper(invoke);
});