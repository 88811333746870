define('@abcum/ember-helpers/helpers/pattern-decimal', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.patternDecimal = patternDecimal;
	function patternDecimal() {
		return '([+|-])?([0-9.]+)';
	}

	exports.default = Ember.Helper.helper(patternDecimal);
});