define('@abcum/ember-helpers/helpers/random', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({

		compute([min, max]) {
			return function (params) {
				return Math.random(params[0] || min, params[1] || max);
			};
		}

	});
});