define('@abcum/ember-helpers/helpers/prevent-default', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.preventDefault = preventDefault;
	function preventDefault() {
		return function (event) {
			event.preventDefault();
			return event;
		};
	}

	exports.default = Ember.Helper.helper(preventDefault);
});