define('@abcum/ember-helpers/helpers/lte', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.lte = lte;
	function lte(params) {
		return params[0] <= params[1];
	}

	exports.default = Ember.Helper.helper(lte);
});