define("ember-models-table/templates/components/models-table/themes/semanticui/global-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8e91G7Rz",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1,[[28,\"hash\",null,[[\"themeInstance\"],[[24,[\"themeInstance\"]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"globalSearch \",[24,[\"themeInstance\",\"globalFilterWrapper\"]]]]],[8],[0,\"\\n    \"],[7,\"label\",true],[11,\"for\",[22,\"inputId\"]],[10,\"class\",\"ui label\"],[8],[0,\"\\n      \"],[1,[24,[\"themeInstance\",\"messages\",\"searchLabel\"]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"id\",\"type\",\"value\",\"enter\",\"class\",\"placeholder\"],[[24,[\"inputId\"]],\"text\",[24,[\"value\"]],[28,\"action\",[[23,0,[]],\"noop\"],null],\"filterString\",[24,[\"themeInstance\",\"messages\",\"searchPlaceholder\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"globalFilterUsed\"]]],null,{\"statements\":[[0,\"      \"],[7,\"i\",true],[11,\"class\",[29,[\"clearFilterIcon \",[24,[\"themeInstance\",\"clearFilterIcon\"]]]]],[10,\"role\",\"button\"],[11,\"aria-label\",[24,[\"themeInstance\",\"messages\",\"clearGlobalFilter\"]]],[11,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"value\"]]],null],\"\"],null]],[8],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/themes/semanticui/global-filter.hbs"
    }
  });

  _exports.default = _default;
});