define('@abcum/ember-helpers/helpers/prompt', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({

		compute([...params]) {
			return (...passed) => {
				return window.prompt(params.concat(passed).join(' '));
			};
		}

	});
});