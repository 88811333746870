define('@abcum/ember-helpers/helpers/pattern-alphanum', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.patternAlphanumeric = patternAlphanumeric;
	function patternAlphanumeric() {
		return '[a-zA-Z0-9-+]+';
	}

	exports.default = Ember.Helper.helper(patternAlphanumeric);
});