define('@abcum/ember-helpers/helpers/mod', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.mod = mod;
	function mod(params) {
		return params.reduce((a, b) => a % b);
	}

	exports.default = Ember.Helper.helper(mod);
});