define('@abcum/ember-helpers/helpers/swapcase', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.swapcase = swapcase;
	function swapcase([value = '']) {
		return String(value).replace(/([a-z]+)|([A-Z]+)/g, function ($0, $1) {
			return $1 ? $0.toUpperCase() : $0.toLowerCase();
		});
	}

	exports.default = Ember.Helper.helper(swapcase);
});