define('@abcum/ember-helpers/helpers/titleize', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.titleize = titleize;
	function titleize([value = '']) {
		return String(value).toLowerCase().replace(/(?:^|\s|-|\/)\S/g, function ($1) {
			return $1.toUpperCase();
		});
	}

	exports.default = Ember.Helper.helper(titleize);
});