define('@abcum/ember-helpers/helpers/search-by', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});


	function isString(value) {
		return Ember.typeOf(value) === 'string';
	}

	exports.default = Ember.Helper.extend({

		compute([...params], optns = {}) {

			let props = params.slice(0, -2);
			let array = params.slice(-1)[0];
			let value = params.slice(-2)[0];

			this.set('props', props);
			this.set('value', value);
			this.set('array', array);
			this.set('optns', optns);

			return this.get('content');
		},

		changed: Ember.observer('content', function () {
			this.recompute();
		}),

		searchDidChange: Ember.observer('props', 'value', 'optns', function () {

			let props = Ember.get(this, 'props');
			let value = Ember.get(this, 'value');
			let optns = Ember.get(this, 'optns');

			if (Ember.typeOf(value) === 'number') {
				value = String(value).split(' ');
			}

			if (Ember.typeOf(value) === 'string') {
				value = value.toLowerCase().split(' ');
			}

			if (Ember.isEmpty(props)) {
				Ember.defineProperty(this, 'content', []);
				return;
			}

			if (!value) {
				Ember.defineProperty(this, 'content', Ember.computed.uniq('array'));
				return;
			}

			if (!Ember.isArray(props)) {
				Ember.defineProperty(this, 'content', Ember.computed.filter('array'));
				return;
			}

			if (!Ember.isArray(value)) {
				Ember.defineProperty(this, 'content', Ember.computed.filter('array'));
				return;
			}

			Ember.defineProperty(this, 'content', Ember.computed.filter('array', item => {
				return Ember.A(value).every(v => {
					return Ember.A(props).any(p => {

						let field = Ember.get(item, p);

						if (isString(field)) {
							switch (optns.exact) {
								case true:
									return field.toLowerCase() == v;
								default:
									return field.toLowerCase().indexOf(v) !== -1;
							}
						}

						if (Ember.isArray(field)) {
							return Ember.A(field).any(f => {
								switch (optns.exact) {
									case true:
										return f.toLowerCase() == v;
									default:
										return f.toLowerCase().indexOf(v) !== -1;
								}
							});
						}
					});
				});
			}));
		})

	});
});