define("ember-paper/templates/components/paper-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cRFQ+jfJ",
    "block": "{\"symbols\":[\"controls\",\"&default\"],\"statements\":[[4,\"with\",[[28,\"hash\",null,[[\"checkbox\",\"button\",\"switch\",\"radio\"],[[28,\"component\",[\"paper-checkbox\"],[[\"parentComponent\",\"bubbles\",\"shouldRegister\"],[[23,0,[]],false,true]]],[28,\"component\",[\"paper-button\"],[[\"parentComponent\",\"bubbles\",\"shouldRegister\",\"skipProxy\"],[[23,0,[]],false,true,true]]],[28,\"component\",[\"paper-switch\"],[[\"parentComponent\",\"bubbles\",\"shouldRegister\"],[[23,0,[]],false,true]]],[28,\"component\",[\"paper-radio-proxiable\"],[[\"parentComponent\",\"bubbles\",\"shouldRegister\"],[[23,0,[]],false,true]]]]]]],null,{\"statements\":[[0,\"\\n\"],[4,\"if\",[[24,[\"hasPrimaryAction\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"md-button md-no-style\"],[8],[0,\"\\n      \"],[1,[28,\"paper-button\",null,[[\"class\",\"onClick\",\"href\",\"target\",\"onMouseEnter\",\"onMouseLeave\"],[\"md-no-style\",[24,[\"onClick\"]],[24,[\"href\"]],[24,[\"target\"]],[28,\"action\",[[23,0,[]],[23,0,[\"handleMouseEnter\"]]],null],[28,\"action\",[[23,0,[]],[23,0,[\"handleMouseLeave\"]]],null]]]],false],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"md-list-item-inner\"],[8],[0,\"\\n        \"],[14,2,[[23,1,[]]]],[0,\"\\n      \"],[9],[0,\"\\n      \"],[5,\"paper-ripple\",[],[[\"@noink\",\"@dimBackground\"],[[23,0,[\"noink\"]],true]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"md-no-style md-list-item-inner\"],[8],[0,\"\\n      \"],[14,2,[[23,1,[]]]],[0,\"\\n      \"],[5,\"paper-ripple\",[],[[\"@noink\",\"@dimBackground\"],[[23,0,[\"noink\"]],true]]],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/templates/components/paper-item.hbs"
    }
  });

  _exports.default = _default;
});