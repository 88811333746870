define('@abcum/ember-helpers/helpers/and', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.and = and;
	function and(params) {
		return params.every(p => !!p);
	}

	exports.default = Ember.Helper.helper(and);
});