define("ember-composable-helpers/utils/includes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = includes;

  function includes(haystack, ...args) {
    let finder = haystack.includes || haystack.contains;
    return finder.apply(haystack, args);
  }
});