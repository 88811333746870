define('@abcum/ember-helpers/helpers/stop-propagation', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.stopPropagation = stopPropagation;
	function stopPropagation() {
		return function (event) {
			event.stopPropagation();
			return event;
		};
	}

	exports.default = Ember.Helper.helper(stopPropagation);
});