define('@abcum/ember-helpers/helpers/ends-with', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.endsWith = endsWith;
	function endsWith([str, suffix]) {
		return String(str).indexOf(String(suffix), String(str).length - String(suffix).length) !== -1;
	}

	exports.default = Ember.Helper.helper(endsWith);
});