define("ember-models-table/templates/components/models-table/no-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tSEfcN/5",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"td\",true],[11,\"colspan\",[22,\"realColumnsCount\"]],[8],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"html-safe\",[[24,[\"themeInstance\",\"messages\",\"noDataToShow\"]]],null],false],[0,\"\\n\"]],\"parameters\":[]}],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/no-data.hbs"
    }
  });

  _exports.default = _default;
});