define('@abcum/ember-helpers/helpers/truncate', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.truncate = truncate;
	function truncate([str, length]) {
		return String(str).length > parseInt(length) ? String(str).substring(0, parseInt(length)) + '...' : str;
	}

	exports.default = Ember.Helper.helper(truncate);
});