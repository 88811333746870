define('ember-paper-expansion-panel/components/paper-expansion-panel/expanded/content', ['exports', 'ember-paper-expansion-panel/templates/components/paper-expansion-panel/expanded/content'], function (exports, _content) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _content.default,
    tagName: 'md-expansion-panel-content'
  });
});