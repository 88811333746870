define('@abcum/ember-helpers/helpers/open', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({

		compute([...main], hash, ...options) {
			return function (...rest) {
				Object.keys(hash).forEach(function (key) {
					options.push(`${key}=${hash[key]}`);
				});
				window.open(main.join('') + rest.join(''), null, options.join(','));
				return main.join('') + rest.join('');
			};
		}

	});
});