define('@abcum/ember-helpers/helpers/replace', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.replace = replace;
	function replace([value = '', search = '', replace = '']) {
		return String(value).replace(search, replace);
	}

	exports.default = Ember.Helper.helper(replace);
});