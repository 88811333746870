define('@abcum/ember-helpers/helpers/define', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({

		compute([obj, key, val]) {
			this.set('val', val);
			Ember.set(obj, key, val);
		},

		changed: Ember.observer('val', function () {
			this.recompute();
		})

	});
});