define('@abcum/ember-charts/components/network-view-node', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		tabindex: 0,

		tagName: undefined,

		didInsertElement() {
			this._super(...arguments);
			this.get('register')(this);
		},

		willDestroyElement() {
			this._super(...arguments);
			this.get('unregister')(this);
		},

		didUpdateAttrs() {
			this._super(...arguments);
			this.get('reregister')(this);
		}

	});
});