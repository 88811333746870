define('@abcum/ember-helpers/helpers/inc', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.inc = inc;
	function inc([value, amount]) {
		return parseFloat(value) + (parseFloat(amount) || 1);
	}

	exports.default = Ember.Helper.helper(inc);
});