define('@abcum/ember-helpers/helpers/bytes', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.bytes = bytes;
	function bytes([val]) {
		let amnt = parseFloat(val) || 0;
		let unit = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
		let size = parseInt(Math.floor(Math.log(amnt) / Math.log(1024)));
		return Math.round(amnt / Math.pow(1024, size), 2) + ' ' + unit[size];
	}

	exports.default = Ember.Helper.helper(bytes);
});