define("ember-google-charts/utils/visualization-names", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    area: 'AreaChart',
    bar: 'BarChart',
    bubble: 'BubbleChart',
    calendar: 'Calendar',
    candlestick: 'CandlestickChart',
    column: 'ColumnChart',
    combo: 'ComboChart',
    gantt: 'Gantt',
    gauge: 'Gauge',
    geo: 'GeoChart',
    histogram: 'Histogram',
    line: 'LineChart',
    orgchart: 'OrgChart',
    pie: 'PieChart',
    sankey: 'Sankey',
    scatter: 'ScatterChart',
    steppedArea: 'SteppedAreaChart',
    table: 'Table',
    timeline: 'Timeline',
    treemap: 'Treemap'
  };
  _exports.default = _default;
});