define('@abcum/ember-helpers/helpers/object-fetch', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({

		compute([key]) {
			return obj => {
				return Ember.get(obj, key);
			};
		}

	});
});