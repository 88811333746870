define('@abcum/ember-helpers/utils/country3', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = country3;
	function country3(code) {
		switch (code) {
			case 'AFG':
				return 'Afganistan';
			case 'ALA':
				return 'Åland Islands';
			case 'ALB':
				return 'Albania';
			case 'DZA':
				return 'Algeria';
			case 'ASM':
				return 'American Samoa';
			case 'AND':
				return 'Andorra';
			case 'AGO':
				return 'Angola';
			case 'AIA':
				return 'Anguilla';
			case 'ATG':
				return 'Antigua & Barbuda';
			case 'ARG':
				return 'Argentina';
			case 'ARM':
				return 'Armenia';
			case 'ABW':
				return 'Aruba';
			case 'AUS':
				return 'Australia';
			case 'AUT':
				return 'Austria';
			case 'AZE':
				return 'Azerbaijan';
			case 'BHS':
				return 'Bahamas';
			case 'BHR':
				return 'Bahrain';
			case 'BGD':
				return 'Bangladesh';
			case 'BRB':
				return 'Barbados';
			case 'BLR':
				return 'Belarus';
			case 'BEL':
				return 'Belgium';
			case 'BLZ':
				return 'Belize';
			case 'BEN':
				return 'Benin';
			case 'BMU':
				return 'Bermuda';
			case 'BTN':
				return 'Bhutan';
			case 'BOL':
				return 'Bolivia';
			case 'BES':
				return 'Bonaire';
			case 'BIH':
				return 'Bosnia-Herzegovina';
			case 'BWA':
				return 'Botswana';
			case 'BRA':
				return 'Brazil';
			case 'IOT':
				return 'British Indian Ocean Territory';
			case 'VGB':
				return 'British Virgin Islands';
			case 'BRN':
				return 'Brunei Darussalam';
			case 'BGR':
				return 'Bulgaria';
			case 'BFA':
				return 'Burkina Faso';
			case 'BDI':
				return 'Burundi';
			case 'KHM':
				return 'Cambodia';
			case 'CMR':
				return 'Cameroon';
			case 'CAN':
				return 'Canada';
			case 'CPV':
				return 'Cape Verde Islands';
			case 'CYM':
				return 'Cayman Islands';
			case 'CAF':
				return 'Central African Republic';
			case 'TCD':
				return 'Chad';
			case 'CHL':
				return 'Chile';
			case 'CHN':
				return 'China';
			case 'CXR':
				return 'Christmas Island';
			case 'CCK':
				return 'Cocos (Keeling) Islands';
			case 'COL':
				return 'Colombia';
			case 'COM':
				return 'Comoros';
			case 'COG':
				return 'Congo (Brazzaville)';
			case 'ZAR':
				return 'Congo (Kinshasa)';
			case 'COK':
				return 'Cook Islands';
			case 'CRI':
				return 'Costa Rica';
			case 'HRV':
				return 'Croatia';
			case 'CUB':
				return 'Cuba';
			case 'CUW':
				return 'Curaçao';
			case 'CYP':
				return 'Cyprus';
			case 'CZE':
				return 'Czech Republic';
			case 'DNK':
				return 'Denmark';
			case 'DJI':
				return 'Djibouti';
			case 'DMA':
				return 'Dominica';
			case 'DOM':
				return 'Dominican Republic';
			case 'TLS':
				return 'East Timor';
			case 'ECU':
				return 'Ecuador';
			case 'EGY':
				return 'Egypt';
			case 'SLV':
				return 'El Salvador';
			case 'GNQ':
				return 'Equatorial Guinea';
			case 'ERI':
				return 'Eritrea';
			case 'EST':
				return 'Estonia';
			case 'ETH':
				return 'Ethiopia';
			case 'FRO':
				return 'Faeroe Islands';
			case 'FLK':
				return 'Falkland Islands';
			case 'FJI':
				return 'Fiji';
			case 'FIN':
				return 'Finland';
			case 'FRA':
				return 'France';
			case 'GUF':
				return 'French Guiana';
			case 'PYF':
				return 'French Polynesia';
			case 'ATF':
				return 'French Southern Territories';
			case 'GAB':
				return 'Gabon';
			case 'GMB':
				return 'Gambia';
			case 'GEO':
				return 'Georgia';
			case 'DEU':
				return 'Germany';
			case 'GHA':
				return 'Ghana';
			case 'GIB':
				return 'Gibralter';
			case 'GRC':
				return 'Greece';
			case 'GRL':
				return 'Greenland';
			case 'GRD':
				return 'Grenada';
			case 'GLP':
				return 'Guadeloupe';
			case 'GUM':
				return 'Guam';
			case 'GTM':
				return 'Guatemala';
			case 'GGY':
				return 'Guernsey';
			case 'GIN':
				return 'Guinea';
			case 'GNB':
				return 'Guinea-Bissau';
			case 'GUY':
				return 'Guyana';
			case 'HTI':
				return 'Haiti';
			case 'VAT':
				return 'Holy See';
			case 'HND':
				return 'Honduras';
			case 'HKG':
				return 'Hong Kong';
			case 'HUN':
				return 'Hungary';
			case 'ISL':
				return 'Iceland';
			case 'IND':
				return 'India';
			case 'IDN':
				return 'Indonesia';
			case 'IRN':
				return 'Iran';
			case 'IRQ':
				return 'Iraq';
			case 'IRL':
				return 'Ireland';
			case 'IMN':
				return 'Isle of Man';
			case 'ISR':
				return 'Israel';
			case 'ITA':
				return 'Italy';
			case 'CIV':
				return 'Ivory Coast';
			case 'JAM':
				return 'Jamaica';
			case 'JPN':
				return 'Japan';
			case 'JEY':
				return 'Jersey';
			case 'JOR':
				return 'Jordan';
			case 'KAZ':
				return 'Kazakhstan';
			case 'KEN':
				return 'Kenya';
			case 'KIR':
				return 'Kiribati';
			case 'KWT':
				return 'Kuwait';
			case 'KGZ':
				return 'Kyrgyzstan';
			case 'LAO':
				return 'Laos';
			case 'LVA':
				return 'Latvia';
			case 'LBN':
				return 'Lebanon';
			case 'LSO':
				return 'Lesotho';
			case 'LBR':
				return 'Liberia';
			case 'LBY':
				return 'Libya';
			case 'LIE':
				return 'Liechtenstein';
			case 'LTU':
				return 'Lithuania';
			case 'LUX':
				return 'Luxembourg';
			case 'MAC':
				return 'Macau';
			case 'MKD':
				return 'Macedonia';
			case 'MDG':
				return 'Madagascar';
			case 'MWI':
				return 'Malawi';
			case 'MYS':
				return 'Malaysia';
			case 'MDV':
				return 'Maldives';
			case 'MLI':
				return 'Mali';
			case 'MLT':
				return 'Malta';
			case 'MHL':
				return 'Marshall Islands';
			case 'MTQ':
				return 'Martinique';
			case 'MRT':
				return 'Mauritania';
			case 'MUS':
				return 'Mauritius';
			case 'MYT':
				return 'Mayotte';
			case 'MEX':
				return 'Mexico';
			case 'FSM':
				return 'Micronesia';
			case 'MDA':
				return 'Moldova';
			case 'MCO':
				return 'Monaco';
			case 'MNG':
				return 'Mongolia';
			case 'MNE':
				return 'Montenegro';
			case 'MSR':
				return 'Montserrat';
			case 'MAR':
				return 'Morocco';
			case 'MOZ':
				return 'Mozambique';
			case 'MMR':
				return 'Myanmar';
			case 'NAM':
				return 'Namibia';
			case 'NRU':
				return 'Nauru';
			case 'NPL':
				return 'Nepal';
			case 'ANT':
				return 'Netherlands Antilles';
			case 'NLD':
				return 'Netherlands';
			case 'NCL':
				return 'New Caledonia';
			case 'NZL':
				return 'New Zealand';
			case 'NIC':
				return 'Nicaragua';
			case 'NER':
				return 'Niger';
			case 'NGA':
				return 'Nigeria';
			case 'NIU':
				return 'Niue';
			case 'NFK':
				return 'Norfolk Island';
			case 'PRK':
				return 'North Korea';
			case 'MNP':
				return 'Northern Mariana Islands';
			case 'NOR':
				return 'Norway';
			case 'OMN':
				return 'Oman';
			case 'PAK':
				return 'Pakistan';
			case 'PLW':
				return 'Palau';
			case 'PSE':
				return 'Palestinian Territories';
			case 'PAN':
				return 'Panama';
			case 'PNG':
				return 'Papua New Guinea';
			case 'PRY':
				return 'Paraguay';
			case 'PER':
				return 'Peru';
			case 'PHL':
				return 'Philippines';
			case 'PCN':
				return 'Pitcairn Islands';
			case 'POL':
				return 'Poland';
			case 'PRT':
				return 'Portugal';
			case 'PRI':
				return 'Puerto Rico';
			case 'QAT':
				return 'Qatar';
			case 'ROU':
				return 'Romania';
			case 'RUS':
				return 'Russia';
			case 'RWA':
				return 'Rwanda';
			case 'REU':
				return 'Réunion';
			case 'BLM':
				return 'Saint Barthélemy';
			case 'KNA':
				return 'Saint Christopher & Nevis';
			case 'SHN':
				return 'Saint Helena';
			case 'LCA':
				return 'Saint Lucia';
			case 'MAF':
				return 'Saint Martin';
			case 'SPM':
				return 'Saint Pierre & Miquelon';
			case 'VCT':
				return 'Saint Vincent & The Grenadines';
			case 'WSM':
				return 'Samoa';
			case 'SMR':
				return 'San Marino';
			case 'STP':
				return 'Sao Tome & Principe';
			case 'SAU':
				return 'Saudi Arabia';
			case 'SEN':
				return 'Senegal';
			case 'SRB':
				return 'Serbia';
			case 'SYC':
				return 'Seychelles';
			case 'SLE':
				return 'Sierra Leone';
			case 'SGP':
				return 'Singapore';
			case 'SXM':
				return 'Sint Maarten';
			case 'SVK':
				return 'Slovakia';
			case 'SVN':
				return 'Slovenia';
			case 'SLB':
				return 'Solomon Islands';
			case 'SOM':
				return 'Somalia';
			case 'ZAF':
				return 'South Africa';
			case 'SGS':
				return 'South Georgia & The South Sandwish Islands';
			case 'KOR':
				return 'South Korea';
			case 'SSD':
				return 'South Sudan';
			case 'ESP':
				return 'Spain';
			case 'LKA':
				return 'Sri Lanka';
			case 'SDN':
				return 'Sudan';
			case 'SUR':
				return 'Suriname';
			case 'SWZ':
				return 'Swaziland';
			case 'SWE':
				return 'Sweden';
			case 'CHE':
				return 'Switzerland';
			case 'SYR':
				return 'Syria';
			case 'TWN':
				return 'Taiwan';
			case 'TJK':
				return 'Tajikistan';
			case 'TZA':
				return 'Tanzania';
			case 'THA':
				return 'Thailand';
			case 'TGO':
				return 'Togo';
			case 'TKL':
				return 'Tokelau';
			case 'TON':
				return 'Tonga';
			case 'TTO':
				return 'Trinidad & Tobago';
			case 'TUN':
				return 'Tunisia';
			case 'TUR':
				return 'Turkey';
			case 'TKM':
				return 'Turkmenistan';
			case 'TCA':
				return 'Turks & Caicos Islands';
			case 'TUV':
				return 'Tuvalu';
			case 'UGA':
				return 'Uganda';
			case 'UKR':
				return 'Ukraine';
			case 'ARE':
				return 'United Arab Emirates';
			case 'GBR':
				return 'United Kingdom';
			case 'USA':
				return 'United States';
			case 'VIR':
				return 'United States Virgin Islands';
			case 'URY':
				return 'Uruguay';
			case 'UZB':
				return 'Uzbekistan';
			case 'VUT':
				return 'Vanuatu';
			case 'VEN':
				return 'Venezuela';
			case 'VNM':
				return 'Vietnam';
			case 'WLF':
				return 'Wallis & Futuna';
			case 'ESH':
				return 'Western Sahara';
			case 'YEM':
				return 'Yemen';
			case 'ZMB':
				return 'Zambia';
			case 'ZWE':
				return 'Zimbabwe';
			default:
				return undefined;
		}
	}
});