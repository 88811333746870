define('ember-paper-expansion-panel/components/paper-expansion-panel', ['exports', 'ember-paper-expansion-panel/templates/components/paper-expansion-panel'], function (exports, _paperExpansionPanel) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _paperExpansionPanel.default,
    tagName: 'md-expansion-panel',
    classNameBindings: ['expanded:md-open:md-close'],

    expanded: false,

    expand(event) {
      this.set('expanded', true);
      if (this.get('onExpandedChange')) {
        this.get('onExpandedChange')(true, event);
      }
    },

    collapse(event) {
      this.set('expanded', false);
      if (this.get('onExpandedChange')) {
        this.get('onExpandedChange')(false, event);
      }
    },

    keyDown(ev) {
      switch (ev.keyCode) {
        case 13:
          // enter
          this.expand();
          break;
        case 27:
          // escape
          this.collapse();
          break;
      }
    }
  });
});