define('@abcum/ember-helpers/helpers/div', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.div = div;
	function div(params) {
		return params.reduce((a, b) => a / b);
	}

	exports.default = Ember.Helper.helper(div);
});