enifed("@ember/-internals/views/lib/utils/lookup-component", ["exports"], function (_exports) {
  "use strict";

  _exports.default = lookupComponent;

  function lookupModuleUnificationComponentPair(componentLookup, owner, name, options) {
    var localComponent = componentLookup.componentFor(name, owner, options);
    var localLayout = componentLookup.layoutFor(name, owner, options);
    var globalComponent = componentLookup.componentFor(name, owner);
    var globalLayout = componentLookup.layoutFor(name, owner); // TODO: we shouldn't have to recheck fallback, we should have a lookup that doesn't fallback

    if (localComponent !== undefined && globalComponent !== undefined && globalComponent.class === localComponent.class) {
      localComponent = undefined;
    }

    if (localLayout !== undefined && globalLayout !== undefined && localLayout.referrer.moduleName === globalLayout.referrer.moduleName) {
      localLayout = undefined;
    }

    if (localLayout !== undefined || localComponent !== undefined) {
      return {
        layout: localLayout,
        component: localComponent
      };
    }

    return {
      layout: globalLayout,
      component: globalComponent
    };
  }

  function lookupComponentPair(componentLookup, owner, name, options) {
    if (false
    /* EMBER_MODULE_UNIFICATION */
    ) {
        return lookupModuleUnificationComponentPair(componentLookup, owner, name, options);
      }

    var component = componentLookup.componentFor(name, owner, options);
    var layout = componentLookup.layoutFor(name, owner, options);
    var result = {
      layout,
      component
    };
    return result;
  }

  function lookupComponent(owner, name, options) {
    var componentLookup = owner.lookup('component-lookup:main');

    if (options && (options.source || options.namespace)) {
      var localResult = lookupComponentPair(componentLookup, owner, name, options);

      if (localResult.component || localResult.layout) {
        return localResult;
      }
    }

    return lookupComponentPair(componentLookup, owner, name);
  }
});