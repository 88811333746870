define('@abcum/ember-helpers/helpers/pattern-url', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.patternUrl = patternUrl;
	function patternUrl() {
		return '[a-zA-Z0-9-+@_:~?#!&,./()$*]+';
	}

	exports.default = Ember.Helper.helper(patternUrl);
});