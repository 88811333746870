define('@abcum/ember-helpers/helpers/w', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.w = w;
	function w([...params]) {
		return params.map(String).map(Ember.String.w).reduce((words, other) => words.concat(other));
	}

	exports.default = Ember.Helper.helper(w);
});