define('@abcum/ember-helpers/helpers/email', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.email = email;
	function email([value]) {
		return String('mailto:' + value).replace(/[\u00A0-\u9999<>&]/gim, i => {
			return `&#${i.charCodeAt(0)};`;
		});
	}

	exports.default = Ember.Helper.helper(email);
});