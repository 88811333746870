define('@abcum/ember-helpers/helpers/not', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.not = not;
	function not(params) {
		return params.every(p => !p);
	}

	exports.default = Ember.Helper.helper(not);
});