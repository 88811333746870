define('@abcum/ember-helpers/helpers/chain', ['exports', '@abcum/ember-helpers/utils/is-promise'], function (exports, _isPromise) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.chain = chain;
	function chain(actions = []) {
		return function (...args) {

			return actions.reduce((val, fnc, idx) => {

				if (idx === 0) {
					if (typeof fnc === 'function') {
						return fnc(...args);
					}
					return fnc;
				}

				if ((0, _isPromise.default)(val)) {
					return val.then(fnc);
				}

				if (typeof fnc === 'function') {
					return fnc(val);
				}

				return fnc;
			}, undefined);
		};
	}

	exports.default = Ember.Helper.helper(chain);
});