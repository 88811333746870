define('@abcum/ember-helpers/helpers/drag-set', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.dragSet = dragSet;
	function dragSet([type, value]) {
		return function (event) {
			if (type === 'application/json') {
				value = JSON.stringify(value);
			}
			event.dataTransfer.setData(type, value);
			return event;
		};
	}

	exports.default = Ember.Helper.helper(dragSet);
});