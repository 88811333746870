define('@abcum/ember-helpers/components/input-stars', ['exports', '@abcum/ember-helpers/templates/components/input-stars'], function (exports, _inputStars) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({

		layout: _inputStars.default,

		min: 1,

		max: 5,

		value: 0,

		colour: '#444',

		disabled: false,

		tagName: 'input-stars',

		stars: Ember.computed('min', 'max', function () {
			let b = this.get('min');
			let e = this.get('max');
			return Array.apply(null, Array(e - b + 1)).map((_, n) => n + b);
		}),

		actions: {

			toggle(value) {

				if (this.disabled) return;

				if (this.get('value') === value) {
					this.decrementProperty('value');
				} else {
					this.set('value', value);
				}

				let func = this.get('on-change');

				if (Ember.typeOf(func) === 'function') {
					func(this.get('value'));
				}
			}

		}

	});
});