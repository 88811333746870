define('@abcum/ember-helpers/helpers/rsvp-all', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({

		compute(...promises) {
			return Ember.RSVP.all(promises);
		}

	});
});