define("ember-paper/components/paper-select/ebd-content/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vL4vAXTh",
    "block": "{\"symbols\":[\"@dropdown\",\"@renderInPlace\",\"@hPosition\",\"@preventScroll\",\"@rootEventType\",\"@vPosition\",\"@destination\",\"@top\",\"@left\",\"@right\",\"@height\",\"&attrs\",\"@searchEnabled\",\"&default\"],\"statements\":[[4,\"if\",[[23,1,[\"isOpen\"]]],null,{\"statements\":[[4,\"if\",[[23,2,[]]],null,{\"statements\":[[0,\"    \"],[5,\"paper-backdrop\",[[12,\"class\",\"md-select-backdrop md-click-catcher\"]],[[\"@onClick\"],[[28,\"action\",[[23,0,[]],[23,1,[\"actions\",\"close\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"in-element\",[[23,0,[\"destinationElement\"]]],[[\"guid\",\"nextSibling\"],[\"%cursor:0%\",null]],{\"statements\":[[0,\"    \"],[5,\"paper-backdrop\",[[12,\"class\",\"md-select-backdrop md-click-catcher\"]],[[\"@onClick\"],[[28,\"action\",[[23,0,[]],[23,1,[\"actions\",\"close\"]]],null]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"\\n\"],[5,\"basic-dropdown-content\",[[12,\"class\",[29,[\"md-select-menu-container md-clickable \",[28,\"if\",[[23,0,[\"isActive\"]],\"md-active\"],null]]]],[13,12],[3,\"did-insert\",[[23,0,[\"animateIn\"]]]],[3,\"will-destroy\",[[23,0,[\"animateOut\"]]]]],[[\"@dropdown\",\"@hPosition\",\"@renderInPlace\",\"@preventScroll\",\"@rootEventType\",\"@vPosition\",\"@destination\",\"@top\",\"@left\",\"@right\",\"@height\",\"@otherStyles\",\"@animationEnabled\",\"@shouldReposition\"],[[23,1,[]],[23,3,[]],[23,2,[]],[23,4,[]],[23,5,[]],[23,6,[]],[23,7,[]],[23,8,[]],[23,9,[]],[23,10,[]],[23,11,[]],[23,0,[\"customStyles\"]],false,[23,0,[\"shouldReposition\"]]]],{\"statements\":[[0,\"\\n\\n\"],[0,\"  \"],[7,\"md-select-menu\",false],[12,\"class\",[29,[\"md-default-theme \",[28,\"if\",[[23,13,[]],\"md-overflow\"],null]]]],[3,\"on\",[\"keydown\",[23,0,[\"handleKeyDown\"]]]],[8],[0,\"\\n    \"],[14,14],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-paper/components/paper-select/ebd-content/template.hbs"
    }
  });

  _exports.default = _default;
});