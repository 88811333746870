define('@abcum/ember-helpers/helpers/scroll-to', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({

		compute(args, { position = 0, duration = 0, element, container }) {
			return function () {

				if (typeof FastBoot !== 'undefined') {
					return;
				}

				if (element) {
					position = Ember.$(element).offset().top;
				}

				if (container) {
					container = Ember.$(container);
				} else {
					container = Ember.$(document);
				}

				if (duration > 0) {
					container.animate({ scrollTop: position }, duration);
				} else {
					container.scrollTop(position);
				}
			};
		}

	});
});