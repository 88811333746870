define("ember-vertical-timeline/templates/components/timeline/timeline-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VvWkUevW",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1,[[28,\"hash\",null,[[\"date\"],[[28,\"component\",[\"timeline/timeline-date\"],null]]]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-vertical-timeline/templates/components/timeline/timeline-content.hbs"
    }
  });

  _exports.default = _default;
});