define('@abcum/ember-helpers/helpers/effect-allowed', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.effectAllowed = effectAllowed;
	function effectAllowed(effect = 'move') {
		return function (event) {
			event.dataTransfer.effectAllowed = effect;
			return event;
		};
	}

	exports.default = Ember.Helper.helper(effectAllowed);
});