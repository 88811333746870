define('@abcum/ember-helpers/utils/currency', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = currency;
	function currency(code) {

		if (window.fxrates && window.fxrates[code]) {
			return window.fxrates[code];
		}

		switch (code) {
			case 'AED':
				return 3.67291;
			case 'AFN':
				return 68.79965;
			case 'ALL':
				return 123.452;
			case 'AMD':
				return 476.27;
			case 'ANG':
				return 1.7763;
			case 'AOA':
				return 165.5705;
			case 'ARS':
				return 15.03775;
			case 'AUD':
				return 1.316625;
			case 'AWG':
				return 1.793333;
			case 'AZN':
				return 1.595675;
			case 'BAM':
				return 1.755346;
			case 'BBD':
				return 2;
			case 'BDT':
				return 78.36067;
			case 'BGN':
				return 1.755664;
			case 'BHD':
				return 0.377162;
			case 'BIF':
				return 1654.2675;
			case 'BMD':
				return 1;
			case 'BND':
				return 1.348055;
			case 'BOB':
				return 6.913702;
			case 'BRL':
				return 3.248465;
			case 'BSD':
				return 1;
			case 'BTC':
				return 0.00151172485;
			case 'BTN':
				return 67.0198;
			case 'BWP':
				return 10.6553;
			case 'BYN':
				return 1.99215;
			case 'BYR':
				return 20026.25;
			case 'BZD':
				return 1.996708;
			case 'CAD':
				return 1.307309;
			case 'CDF':
				return 976.5915;
			case 'CHF':
				return 0.972746;
			case 'CLF':
				return 0.024802;
			case 'CLP':
				return 657.8081;
			case 'CNY':
				return 6.641678;
			case 'COP':
				return 3075.918333;
			case 'CRC':
				return 546.7867;
			case 'CUC':
				return 1;
			case 'CUP':
				return 24.728383;
			case 'CVE':
				return 99.086833;
			case 'CZK':
				return 24.24649;
			case 'DJF':
				return 177.270875;
			case 'DKK':
				return 6.672741;
			case 'DOP':
				return 45.90761;
			case 'DZD':
				return 110.18384;
			case 'EEK':
				return 14.067775;
			case 'EGP':
				return 8.882008;
			case 'ERN':
				return 15.2;
			case 'ETB':
				return 22.04594;
			case 'EUR':
				return 0.8954;
			case 'FJD':
				return 2.0704;
			case 'FKP':
				return 0.754905;
			case 'GBP':
				return 0.754905;
			case 'GEL':
				return 2.34934;
			case 'GGP':
				return 0.754905;
			case 'GHS':
				return 3.918565;
			case 'GIP':
				return 0.754905;
			case 'GMD':
				return 42.60172;
			case 'GNF':
				return 8979.4725;
			case 'GTQ':
				return 7.560162;
			case 'GYD':
				return 205.293167;
			case 'HKD':
				return 7.75807;
			case 'HNL':
				return 22.76066;
			case 'HRK':
				return 6.706208;
			case 'HTG':
				return 63.3772;
			case 'HUF':
				return 279.2871;
			case 'IDR':
				return 13101.866667;
			case 'ILS':
				return 3.814836;
			case 'IMP':
				return 0.754905;
			case 'INR':
				return 66.87011;
			case 'IQD':
				return 1167.8832;
			case 'IRR':
				return 30077.5;
			case 'ISK':
				return 119.6675;
			case 'JEP':
				return 0.754905;
			case 'JMD':
				return 126.5163;
			case 'JOD':
				return 0.70817;
			case 'JPY':
				return 102.7503;
			case 'KES':
				return 101.3753;
			case 'KGS':
				return 67.76115;
			case 'KHR':
				return 4049.47;
			case 'KMF':
				return 445.291667;
			case 'KPW':
				return 900.09;
			case 'KRW':
				return 1119.663333;
			case 'KWD':
				return 0.302152;
			case 'KYD':
				return 0.823338;
			case 'KZT':
				return 352.1641;
			case 'LAK':
				return 8073.8725;
			case 'LBP':
				return 1506.835;
			case 'LKR':
				return 145.7136;
			case 'LRD':
				return 90.25;
			case 'LSL':
				return 13.98379;
			case 'LTL':
				return 3.0763;
			case 'LVL':
				return 0.628094;
			case 'LYD':
				return 1.382866;
			case 'MAD':
				return 9.765242;
			case 'MDL':
				return 19.71142;
			case 'MGA':
				return 2935.481667;
			case 'MKD':
				return 55.19813;
			case 'MMK':
				return 1183.545;
			case 'MNT':
				return 2061.666667;
			case 'MOP':
				return 7.984276;
			case 'MRO':
				return 354.426;
			case 'MTL':
				return 0.683602;
			case 'MUR':
				return 35.37165;
			case 'MVR':
				return 15.203333;
			case 'MWK':
				return 716.0245;
			case 'MXN':
				return 18.79016;
			case 'MYR':
				return 4.052172;
			case 'MZN':
				return 68.15;
			case 'NAD':
				return 14.02779;
			case 'NGN':
				return 318.6972;
			case 'NIO':
				return 28.66272;
			case 'NOK':
				return 8.457046;
			case 'NPR':
				return 107.0877;
			case 'NZD':
				return 1.386863;
			case 'OMR':
				return 0.384965;
			case 'PAB':
				return 1;
			case 'PEN':
				return 3.352598;
			case 'PGK':
				return 3.1666;
			case 'PHP':
				return 47.10258;
			case 'PKR':
				return 104.76866;
			case 'PLN':
				return 3.908299;
			case 'PYG':
				return 5563.098333;
			case 'QAR':
				return 3.641523;
			case 'RON':
				return 3.998154;
			case 'RSD':
				return 110.64642;
			case 'RUB':
				return 66.06571;
			case 'RWF':
				return 782.025167;
			case 'SAR':
				return 3.750283;
			case 'SBD':
				return 7.90181;
			case 'SCR':
				return 13.35216;
			case 'SDG':
				return 6.0697;
			case 'SEK':
				return 8.567108;
			case 'SGD':
				return 1.345075;
			case 'SHP':
				return 0.754905;
			case 'SLL':
				return 5520;
			case 'SOS':
				return 580.788333;
			case 'SRD':
				return 7.0925;
			case 'STD':
				return 22092.55;
			case 'SVC':
				return 8.734051;
			case 'SYP':
				return 215.49;
			case 'SZL':
				return 14.02155;
			case 'THB':
				return 34.81373;
			case 'TJS':
				return 7.86825;
			case 'TMT':
				return 3.468267;
			case 'TND':
				return 2.21475;
			case 'TOP':
				return 2.28555;
			case 'TRY':
				return 2.993725;
			case 'TTD':
				return 6.686508;
			case 'TWD':
				return 31.896;
			case 'TZS':
				return 2183.476667;
			case 'UAH':
				return 24.79149;
			case 'UGX':
				return 3372.291667;
			case 'USD':
				return 1;
			case 'UYU':
				return 29.81307;
			case 'UZS':
				return 2969.5;
			case 'VEF':
				return 9.978403;
			case 'VND':
				return 22291.85;
			case 'VUV':
				return 107.663333;
			case 'WST':
				return 2.565833;
			case 'XAF':
				return 590.30654;
			case 'XAG':
				return 0.05015;
			case 'XAU':
				return 0.00074;
			case 'XCD':
				return 2.69902;
			case 'XDR':
				return 0.71769;
			case 'XOF':
				return 592.9039;
			case 'XPD':
				return 0.0017;
			case 'XPF':
				return 107.137875;
			case 'XPT':
				return 0.0009;
			case 'YER':
				return 250.026;
			case 'ZAR':
				return 13.95845;
			case 'ZMK':
				return 5221.025;
			case 'ZMW':
				return 10.229563;
			case 'ZWL':
				return 322.387236;
			default:
				return 1;
		}
	}
});