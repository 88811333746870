define("ember-models-table/templates/components/models-table/row-sorting-cell", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HKpkKhUE",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1,[[28,\"hash\",null,[[\"column\",\"selectedItems\",\"expandedItems\",\"data\",\"themeInstance\",\"expandAllRows\",\"toggleAllSelection\",\"collapseAllRows\"],[[24,[\"column\"]],[24,[\"selectedItems\"]],[24,[\"expandedItems\"]],[24,[\"data\"]],[24,[\"themeInstance\"]],[24,[\"expandAllRows\"]],[24,[\"toggleAllSelection\"]],[24,[\"collapseAllRows\"]]]]]]],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"column\",\"componentForSortCell\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[24,[\"column\",\"componentForSortCell\"]]],[[\"column\",\"selectedItems\",\"expandedItems\",\"data\",\"themeInstance\",\"expandAllRows\",\"toggleAllSelection\",\"collapseAllRows\"],[[24,[\"column\"]],[24,[\"selectedItems\"]],[24,[\"expandedItems\"]],[24,[\"data\"]],[24,[\"themeInstance\"]],[24,[\"expandAllRows\"]],[24,[\"toggleAllSelection\"]],[24,[\"collapseAllRows\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[24,[\"column\",\"title\"]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"column\",\"useSorting\"]]],null,{\"statements\":[[0,\"      \"],[7,\"i\",true],[11,\"class\",[29,[[28,\"if\",[[24,[\"column\",\"sortAsc\"]],[24,[\"themeInstance\",\"sort-asc\"]]],null],\" \",[28,\"if\",[[24,[\"column\",\"sortDesc\"]],[24,[\"themeInstance\",\"sort-desc\"]]],null]]]],[8],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-models-table/templates/components/models-table/row-sorting-cell.hbs"
    }
  });

  _exports.default = _default;
});