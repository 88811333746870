define('@abcum/ember-helpers/helpers/check', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({

		compute([fnc]) {
			return (val, ...args) => val ? fnc(val, ...args) : null;
		}

	});
});