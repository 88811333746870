define('@abcum/ember-helpers/helpers/add', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.add = add;
	function add(params) {
		return params.reduce((a, b) => a + b);
	}

	exports.default = Ember.Helper.helper(add);
});