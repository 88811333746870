define('@abcum/ember-helpers/helpers/round', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.round = round;
	function round(params) {
		return Math.round(params[0]);
	}

	exports.default = Ember.Helper.helper(round);
});