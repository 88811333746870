define('@abcum/ember-helpers/helpers/isnt', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.isnt = isnt;
	function isnt(params) {
		return params.every(p => p !== true);
	}

	exports.default = Ember.Helper.helper(isnt);
});