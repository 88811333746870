define('@abcum/ember-helpers/helpers/transition-to', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({

		router: Ember.inject.service(),

		compute(params, hash) {
			let router = this.get('router');
			return function (...passed) {
				return router.transitionTo(...params, ...passed, { queryParams: hash });
			};
		}

	});
});