define('@abcum/ember-helpers/helpers/inside', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.inside = inside;
	function inside([haystack, needle]) {
		return haystack.indexOf(needle) > -1;
	}

	exports.default = Ember.Helper.helper(inside);
});