define('@abcum/ember-helpers/helpers/toggle', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.toggle = toggle;
	function toggle([obj, prop, ...values]) {
		return function () {

			if (Ember.isPresent(values)) {
				let idx = values.indexOf(Ember.get(obj, prop));
				let nxt = values[idx + 1] || values[0];
				return Ember.set(obj, prop, nxt);
			}

			return Ember.set(obj, prop, !Ember.get(obj, prop));
		};
	}

	exports.default = Ember.Helper.helper(toggle);
});