define('@abcum/ember-helpers/helpers/camelize', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.camelize = camelize;
	function camelize([value = '']) {
		return Ember.String.camelize(String(value));
	}

	exports.default = Ember.Helper.helper(camelize);
});