define("ember-vertical-timeline/components/timeline/timeline-image", ["exports", "ember-in-viewport", "ember-vertical-timeline/templates/components/timeline/timeline-image"], function (_exports, _emberInViewport, _timelineImage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_emberInViewport.default, {
    layout: _timelineImage.default,
    animate: true,
    classNames: 'timeline-image',
    classNameBindings: ['bounceIn:bounce-in:is-hidden', 'animate'],

    didEnterViewport() {
      this.set('bounceIn', true);
    }

  });

  _exports.default = _default;
});