define("liquid-fire/transition-map", ["exports", "liquid-fire/running-transition", "liquid-fire/dsl", "liquid-fire/action", "liquid-fire/constraints"], function (_exports, _runningTransition, _dsl, _action, _constraints) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let TransitionMap = Ember.Service.extend({
    init() {
      this._super(...arguments);

      this.activeCount = 0;
      this.constraints = new _constraints.default();
      let owner = Ember.getOwner(this);
      let config;

      if (owner.factoryFor) {
        let maybeConfig = owner.factoryFor('transitions:main');
        config = maybeConfig && maybeConfig.class;
      } else {
        config = owner._lookupFactory('transitions:main');
      }

      if (config) {
        this.map(config);
      }
    },

    runningTransitions() {
      return this.activeCount;
    },

    incrementRunningTransitions() {
      this.activeCount++;
    },

    decrementRunningTransitions() {
      this.activeCount--;
      Ember.run.next(() => {
        this._maybeResolveIdle();
      });
    },

    waitUntilIdle() {
      if (this._waitingPromise) {
        return this._waitingPromise;
      }

      return this._waitingPromise = new Ember.RSVP.Promise(resolve => {
        this._resolveWaiting = resolve;
        Ember.run.next(() => {
          this._maybeResolveIdle();
        });
      });
    },

    _maybeResolveIdle() {
      if (this.activeCount === 0 && this._resolveWaiting) {
        let resolveWaiting = this._resolveWaiting;
        this._resolveWaiting = null;
        this._waitingPromise = null;
        resolveWaiting();
      }
    },

    lookup(transitionName) {
      let owner = Ember.getOwner(this);
      let handler;

      if (owner.factoryFor) {
        let maybeHandler = owner.factoryFor('transition:' + transitionName);
        handler = maybeHandler && maybeHandler.class;
      } else {
        handler = owner._lookupFactory('transition:' + transitionName);
      }

      if (!handler) {
        throw new Error("unknown transition name: " + transitionName);
      }

      return handler;
    },

    defaultAction() {
      if (!this._defaultAction) {
        this._defaultAction = new _action.default(this.lookup('default'));
      }

      return this._defaultAction;
    },

    constraintsFor(conditions) {
      if (conditions.rules) {
        let constraints = new _constraints.default();
        this.map(conditions.rules, constraints);
        return constraints;
      } else {
        return this.constraints;
      }
    },

    transitionFor(conditions) {
      let action;

      if (conditions.use && conditions.firstTime !== 'yes') {
        action = new _action.default(conditions.use);
        action.validateHandler(this);
      } else {
        let rule = this.constraintsFor(conditions).bestMatch(conditions);

        if (rule) {
          action = rule.use;
        } else {
          action = this.defaultAction();
        }
      }

      return new _runningTransition.default(this, conditions.versions, action);
    },

    map(handler, constraints) {
      if (handler) {
        handler.apply(new _dsl.default(this, constraints || this.constraints));
      }

      return this;
    }

  });

  if (true
  /* DEBUG */
  ) {
    TransitionMap.reopen({
      init() {
        this._super(...arguments);

        if (Ember.testing) {
          this._waiter = () => {
            return this.runningTransitions() === 0;
          };

          Ember.Test.registerWaiter(this._waiter);
        }
      },

      willDestroy() {
        if (this._waiter) {
          Ember.Test.unregisterWaiter(this._waiter);
          this._waiter = null;
        }

        this._super(...arguments);
      }

    });
  }

  TransitionMap.reopenClass({
    map(handler) {
      let t = TransitionMap.create();
      t.map(handler);
      return t;
    }

  });
  var _default = TransitionMap;
  _exports.default = _default;
});