define('@abcum/ember-helpers/helpers/classify', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.classify = classify;
	function classify([value = '']) {
		return Ember.String.classify(String(value));
	}

	exports.default = Ember.Helper.helper(classify);
});