define('@abcum/ember-helpers/helpers/sort-by', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Helper.extend({

		compute([...props]) {

			let param = props.slice(0, -1);
			let array = props.slice().pop();

			if (Ember.isArray(param[0]) || Ember.typeOf(param[0]) === 'function') {
				param = param[0];
			}

			this.set('param', param);
			this.set('array', array);

			return this.get('content');
		},

		changed: Ember.observer('content', function () {
			this.recompute();
		}),

		sortDidChange: Ember.observer('param', function () {

			let param = Ember.get(this, 'param');

			if (Ember.isEmpty(param)) {
				Ember.defineProperty(this, 'content', []);
				return;
			}

			if (typeof param === 'function') {
				Ember.defineProperty(this, 'content', Ember.computed.sort('array', param));
			} else {
				Ember.defineProperty(this, 'content', Ember.computed.sort('array', 'param'));
			}
		})

	});
});