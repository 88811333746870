define('@abcum/ember-helpers/helpers/array', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.array = array;
	function array([...params]) {
		return Ember.A(params);
	}

	exports.default = Ember.Helper.helper(array);
});