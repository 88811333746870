define('@abcum/ember-helpers/helpers/regexp', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.regexp = regexp;
	function regexp([pattern = '', flags = '']) {
		return new RegExp(pattern, flags);
	}

	exports.default = Ember.Helper.helper(regexp);
});